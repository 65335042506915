import React, { useEffect } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { BlogProvider, useBlogs } from '../../../../context/blogs';
import Loading from '../../../../components/loading/Loading';
import { BlogInfo } from '../create-blog/blog-info/BlogInfo';
import { BlogCreationInfo } from '../create-blog/blog-creation-info/BlogCreationInfo';
import { BlogCategories } from '../create-blog/blog-categories/BlogCategories';
import { BlogProducts } from '../create-blog/blog-products/BlogProducts';
import { PERMISSIONS } from '../../../../utils/permissions';
import { BlogGallery } from '../create-blog/blog-gallery/BlogGallery';
import { BlogBreadcrumb } from './blog-breadcrumb/BlogBreadcrumb';

const UpdateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useBlogs();

  useEffect(() => {
    setProductEdit(true);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const UpdateProductSubmit = () => {
  const { form, onCancel, onUpdate, productCategoriesLoading } = useBlogs();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Otkazati</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`)} className="btn btn-primary" type="button">
            <span className="text">Izmeni Blog</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const UpdateBlog = () => {
  return (
    <BlogProvider>
      <BlogBreadcrumb />
      <Section className="section__product section__product--wrapper section__update-product product-form">
        <UpdateProductLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <BlogInfo className="product__card--info" />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <BlogCreationInfo className="product__card--tags" />
                <BlogGallery className="product__card--gallery" />
                {/* <ProductType className="product__card--type" /> */}

                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <BlogCategories className="product__card--tags" />
                {/* <Declaration className="product-description" /> */}
                <BlogProducts className="product__card--tags" />
              </Wrapper>
            </Col>
          </Row>
        </UpdateProductLoader>
        <UpdateProductSubmit />
      </Section>
    </BlogProvider>
  );
};

export default UpdateBlog;
