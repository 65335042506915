import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Row } from '../content';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { notification } from 'antd';

const FeaturedCategoriesSelect = (props) => {
  const { Option } = Select;
  const { allCategories, setReadDataRefetch } = props;
  const hpFeaturedCategories = allCategories.filter((category) => category.hasOwnProperty('isHpFeatured') && category.isHpFeatured === true).map((category) => category._id);
  // filter all categories by hpFeaturedCategories ids
  const featuredCategories = allCategories.filter((category) => hpFeaturedCategories.includes(category._id));
  const [firstFeaturedCategory, setFirstFeaturedCategory] = useState(featuredCategories.filter((category) => category.firstFeatured === true)[0]);
  const [secondFeaturedCategory, setSecondFeaturedCategory] = useState(featuredCategories.filter((category) => category.secondFeatured === true)[0]);

  const firstFeaturedCategoryOptions = allCategories.filter((category) => category._id !== secondFeaturedCategory?._id && category._id !== firstFeaturedCategory?._id);
  const secondFeaturedCategoryOptions = allCategories.filter((category) => category._id !== firstFeaturedCategory?._id && category._id !== secondFeaturedCategory?._id);

  const handleChangeFeaturedCat1 = (e) => {
    const category = allCategories.find((category) => category._id === e);
    setFirstFeaturedCategory(category);
  };

  const handleChangeFeaturedCat2 = (e) => {
    const category = allCategories.find((category) => category._id === e);
    setSecondFeaturedCategory(category);
  };

  const handleSubmitFeaturedCategories = async (e) => {
    e.preventDefault();
    let firstFeaturedCategoryId = firstFeaturedCategory?._id || null;
    let secondFeaturedCategoryId = secondFeaturedCategory?._id || null;
    try {
      const response = await axios.put(`${SERVER_URL}/${AXIOS_API_CALL.updateFeaturedCategories}`, {
        firstFeaturedCategoryId,
        secondFeaturedCategoryId,
      });
      if (response.status === 200) {
        setReadDataRefetch((prevState) => !prevState);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Row className="featured-categories-select">
      <form onSubmit={handleSubmitFeaturedCategories}>
        <div className="select-wrap">
          <div>
            <h4>Istaknute kategorije na početnoj stranici kod slidera:</h4>
            <span className="small">(dve nasumične kategorije prikazane, ukoliko nema izabranih)</span>
          </div>
          <Select
            value={firstFeaturedCategory?.name}
            onChange={(e) => {
              handleChangeFeaturedCat1(e);
            }}
            name="featuredCategory1"
            placeholder="Istaknuta kategorija"
            type="text"
            id="featuredCategory1"
            style={{ width: '100%' }}
          >
            {firstFeaturedCategoryOptions &&
              firstFeaturedCategoryOptions.length > 0 &&
              firstFeaturedCategoryOptions.map((item, index) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
          <Select
            value={secondFeaturedCategory?.name}
            onChange={(e) => {
              handleChangeFeaturedCat2(e);
            }}
            name="featuredCategory2"
            placeholder="Istaknuta kategorija"
            type="text"
            id="featuredCategory1"
            style={{ width: '100%' }}
          >
            {secondFeaturedCategoryOptions &&
              secondFeaturedCategoryOptions.length > 0 &&
              secondFeaturedCategoryOptions.map((item, index) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </div>
        <button className="btn btn-primary pl-2 pr-2">Primeni</button>
      </form>
    </Row>
  );
};

export default FeaturedCategoriesSelect;
