import React, { useEffect, useState } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, handleOnUpload, handleOnRemoveUpload, imageData, createFormError, createFormFields, createBtnLoader, setToggleCreate }) => {
  return (
    <SidebarModal {...createProps} refProp={createModalFormRef} className="sidebar__modal--right" title="Kreiraj deklaraciju">
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
        <Form.Item name="name" label="Naziv:" rules={[{ required: true, message: 'Unesite naziv deklaracije!' }]}>
          <Input
            placeholder="Naziv:"
            onChange={(e) => {
              const nameValue = e.target.value;
              const slugValue = nameValue.toLowerCase().replace(/\s+/g, '-');
              createModalFormRef.current.setFieldsValue({ slug: slugValue });
            }}
            onBlur={(e) => {
              const nameValue = e.target.value;
              const slugValue = nameValue.toLowerCase().replace(/\s+/g, '-');
              createModalFormRef.current.setFieldsValue({ slug: slugValue });
            }}
          />
        </Form.Item>

        <Form.Item name="manufacturer" label="Proizvođač:">
          <Input placeholder="Proizvođač:" />
        </Form.Item>

        <Form.Item name="qualityClass" label="Klasa kvaliteta:">
          <Input placeholder="Klasa kvaliteta:" />
        </Form.Item>

        <Form.Item name="material" label="Materijal:">
          <Input placeholder="Materijal:" />
        </Form.Item>

        <Form.Item name="countryOfOrigin" label="Zemlja porekla:">
          <Input placeholder="Zemlja porekla:" />
        </Form.Item>

        <Form.Item name="importer" label="Uvoznik:">
          <Input placeholder="Uvoznik:" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
          <Row gutter={16}>
            <Col span={12}>
              <Button
                type="button"
                htmlType="button"
                className="btn btn-primary-link btn-block"
                onClick={() => {
                  setToggleCreate(false);
                  createModalFormRef.current.resetFields();
                }}
              >
                <span>Otkazati</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>Kreiraj deklaraciju</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>Kreiranje...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
