import React from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, createFormFields, roles, createBtnLoader, setToggleCreate }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title="Kreiraj korisnika">
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
        <Form.Item name="firstName" label="Ime:" rules={[{ required: true, message: 'Unesite vaše ime!' }]}>
          <Input placeholder="Ime:" />
        </Form.Item>
        <Form.Item name="lastName" label="Prezime:" rules={[{ required: true, message: 'Unesite vaše prezime!' }]}>
          <Input placeholder="Prezime:" />
        </Form.Item>
        <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Unesite vaš email!' }]}>
          <Input placeholder="Email:" />
        </Form.Item>
        {/* <Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
          <Select>
            <Option key={''}>Select State</Option>

            {stateUS.map((item) => {
              return (
                <Option key={item.name} value={item.abbreviation}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item> */}
        {/* <Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
          <Input placeholder="City:" />
        </Form.Item>
        <Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
          <Input placeholder="Address:" />
        </Form.Item>
        <Form.Item name="zipCode" label="Zip Code:" rules={[{ required: false, message: 'Please input your zip code!' }]}>
          <Input placeholder="Zip Code:" />
        </Form.Item>
        <Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
          <Input placeholder="Phone:" />
        </Form.Item> */}
        <Form.Item name="password" label="Lozinka:" rules={[{ required: true, message: 'Unesite vašu lozinku!' }]}>
          <Input.Password placeholder="Lozinka:" />
        </Form.Item>
        <Form.Item name="role" label="Izaberite ulogu:" rules={[{ required: true, message: 'Izaberite ulogu!' }]}>
          <Select>
            <Option key={''}>Izaberite ulogu</Option>

            {roles.map((item) => {
              return (
                <Option key={item.name} value={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="createUserId" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="createUserType" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
          <Row gutter={16}>
            <Col span={12}>
              <Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                <span>Otkazati</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>Kreirajte korisnika</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>Kreiranje...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
