import React, { useEffect } from 'react';
import { Section, Col, Row, Wrapper } from '../../../../components/content';
import { BlogProvider, useBlogs } from '../../../../context/blogs';
import { PERMISSIONS } from '../../../../utils/permissions';
import { BlogBreadcrumb } from './blog-breadcrumb/BlogBreadcrumb';
import { BlogGallery } from './blog-gallery/BlogGallery';
import { BlogInfo } from './blog-info/BlogInfo';
import { BlogCategories } from './blog-categories/BlogCategories';
import { BlogType } from './blog-type/BlogType';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import Loading from '../../../../components/loading/Loading';
import { BlogProducts } from './blog-products/BlogProducts';

const CreateBlogLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useBlogs();

  useEffect(() => {
    setProductEdit(false);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const CreateBlogSubmit = () => {
  const { form, onCancel, onSubmit, productCategoriesLoading } = useBlogs();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Otkazati</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onSubmit(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`)} className="btn btn-primary" type="button">
            <span className="text">Kreiraj Blog</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const CreateBlog = () => {
  return (
    <BlogProvider>
      <BlogBreadcrumb />
      <Section className="section__product section__product--wrapper section__product--create product-form">
        <CreateBlogLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <BlogInfo className="product__card--info" />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <BlogGallery className="product__card--gallery" />
                {/* <ProductType className="product__card--type" /> */}
                {/* <BlogOptions className="product__card--options" /> */}
                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <BlogCategories className="product__card--tags" />
                {/* <Declaration className="product-description" /> */}
                <BlogProducts className="product__card--tags" />
              </Wrapper>
            </Col>
          </Row>
        </CreateBlogLoader>

        <CreateBlogSubmit />
      </Section>
    </BlogProvider>
  );
};

export default CreateBlog;
