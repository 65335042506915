import React, { useEffect, useState } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Editor } from '@tinymce/tinymce-react';
const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, handleOnUpload, handleOnRemoveUpload, imageData, createFormError, createFormFields, createBtnLoader, setToggleCreate }) => {
  const [description, setDescription] = useState('');
  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };
  useEffect(() => {
    setDescription('');
  }, [createProps.active]);

  return (
    <SidebarModal {...createProps} refProp={createModalFormRef} className="sidebar__modal--right" title="Kreiraj brand">
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
        <div className={`form-group upload-image`}>
          {imageData.blob ? (
            <div className="avatar">
              <div className="image-wrapper">
                <img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
              </div>

              <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                <span className="icon" data-cy="image-placeholder"></span>
                <span className="text">Izbriši Fotografiju</span>
              </button>
            </div>
          ) : (
            <div className="upload">
              <div className="upload-wrapper d-flex gap-x-2">
                <div>
                  <span className="text">
                    <div className="text-center">
                      <p className="mb-0">Nema izabrane</p>
                      <p className="mb-0">fotografije</p>
                    </div>
                  </span>
                  <input
                    type="file"
                    name="createImage"
                    id="uploadFile"
                    className="input-upload"
                    onChange={(event) =>
                      handleOnUpload({
                        name: event.target.name,
                        img: event.target.files[0],
                      })
                    }
                  />
                </div>

                <div className="validation-image">
                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                    * Možete uvesti samo JPG/PNG file!
                  </p>

                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                    * Fotografija mora biti manja od 2MB!
                  </p>
                </div>
              </div>
              <label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                <span className="icon">
                  <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                </span>
                <span className="text">Uvezi slike</span>
              </label>
            </div>
          )}
        </div>

        <Form.Item name="name" label="Naziv:" rules={[{ required: true, message: 'Unesite naziv brenda!' }]}>
          <Input
            placeholder="Naziv:"
            onChange={(e) => {
              const nameValue = e.target.value;
              const slugValue = nameValue.toLowerCase().replace(/\s+/g, '-');
              createModalFormRef.current.setFieldsValue({ slug: slugValue });
            }}
            onBlur={(e) => {
              const nameValue = e.target.value;
              const slugValue = nameValue.toLowerCase().replace(/\s+/g, '-');
              createModalFormRef.current.setFieldsValue({ slug: slugValue });
            }}
          />
        </Form.Item>

        <Form.Item name="slug" label="Slug:" rules={[{ required: true, message: 'Unesite slug za brend!' }]}>
          <Input placeholder="Slug:" />
        </Form.Item>

        <Form.Item name="description" label="Opis:" rules={[{ required: true, message: 'Unesite opis brenda!' }]}>
          <div className="select select-result">
            <Editor
              apiKey={apiKey}
              name="description"
              onEditorChange={(newValue, editor) => {
                getTextValue(editor.getContent());
                createModalFormRef.current.setFieldsValue({ description: editor.getContent() });
              }}
              init={{
                height: 600,
                menubar: true,
                plugins: 'table link',
                entity_encoding: 'raw',
              }}
              value={description}
            />
          </div>
        </Form.Item>

        <Form.Item name="metaDescription" label="Seo opis:">
          <Input placeholder="Meta opis:" />
        </Form.Item>
        <Form.Item name="metaKeywords" label="Ključne reči:">
          <Input placeholder="Meta opis:" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
          <Row gutter={16}>
            <Col span={12}>
              <Button
                type="button"
                htmlType="button"
                className="btn btn-primary-link btn-block"
                onClick={() => {
                  setToggleCreate(false);
                  createModalFormRef.current.resetFields();
                }}
              >
                <span>Otkazati</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>Kreirajte brand</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>Kreiranje...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
