import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { slugify, slugifyReplaceAll } from '../../../helpers/helpers';
import { notification } from 'antd';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { CreateAttributesAndUnit } from './create-attributes-and-unit/CreateAttributesAndUnit';
import { CreateUnit } from './create-unit/CreateUnit';
import { UpdateAttributesAndUnit } from './update-attributes-and-unit/UpdateAttributesAndUnit';
import { DeleteAttribute } from './delete-attribute/DeleteAttribute';

const options = [5, 10, 20, 50, 100];

const Attributes = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${currentPage}/${limitPage}/${search || null}/null`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => console.error(err))
        .finally();

      return response;
    } catch (err) {}
  }, [user, currentPage, limitPage, search]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);

      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [createAttUnitBtnLoader, setCreateAttUnitBtnLoader] = useState(false);
  const [createUnitBtnLoader, setCreateUnitBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // CREATE - (attribute)
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({ name: '', slug: '' }); // FORM data
  const [createFormError, setCreateFormError] = useState({ name: false, slug: false }); // ERROR handling

  // CREATE - (unit in attribute)
  const createAttributeUnitFormRef = useRef(null);
  const [toggleCreateUnitAttribute, setToggleCreateUnitAttribute] = useState(false);
  const [createUnitAttributeForm, setCreateUnitAttributeForm] = useState({ name: '' }); // FORM data
  const [createUnitAttribute, setCreateUnitAttribute] = useState([]);
  const [createUnitAttributeFormError, setCreateUnitAttributeFormError] = useState({ name: false }); // ERROR handling

  // CREATE - (unit)
  const createUnitFormRef = useRef(null);
  const [toggleCreateUnit, setToggleCreateUnit] = useState(false);
  const [createUnitForm, setCreateUnitForm] = useState({ name: '' }); // FORM data
  const [createUnitFormError, setCreateUnitFormError] = useState({ name: false }); // ERROR handling
  const [createUnitFormId, setCreateUnitFormId] = useState({ id: '' }); // ID for fetch

  // UPDATE - (attribute)
  const updateFormRef = useRef(null);
  const updateCreateUnitFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateForm, setUpdateForm] = useState({ name: '', slug: '', unit: '' }); // FORM data
  const [updateUnit, setUpdateUnit] = useState([]);
  const [updateFormError, setUpdateFormError] = useState({ name: false, slug: false, unit: false }); // ERROR handling
  const [updateFormId, setUpdateFormId] = useState({ id: '' }); // ID for fetch
  const [updateLoadingForm, setUpdateLoadingForm] = useState(true);
  const [toggleUpdateUnit, setToggleUpdateUnit] = useState(false);

  // DELETE - (attribute)
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: '' }); // ID for fetch

  useEffect(() => {
    if (!!toggleCreate) {
      setToggleCreateUnitAttribute(false);
    }
  }, [toggleCreate]);

  useEffect(() => {
    if (!!toggleUpdate) {
      setToggleUpdateUnit(false);
    }
  }, [toggleUpdate]);

  /**
   * Handle remove unit
   * @param {*} props
   */
  const handleOnRemoveUnit = (props) => {
    const { name, data } = props;

    switch (name) {
      case 'create-attribute-unit':
        const removedattributeUnit = createUnitAttribute.filter((unit) => !unit._id.startsWith(data._id));
        setCreateUnitAttribute(removedattributeUnit);

        // try {
        //   axios
        //     .delete(`${SERVER_URL}/${AXIOS_API_CALL.units}/${data._id}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.token}` } })
        //     .then((res) => {
        //       if (res.status === 200) {
        //         setRefetchCreateUnitRemoved((prevState) => !prevState);
        //       }
        //     })
        //     .catch((err) => console.error(err))
        //     .finally(setTimeout(() => {}, 700));
        // } catch (err) {}

        break;
      case 'update-attribute-unit':
        const removedUnit = updateUnit.filter((unit) => !unit._id.startsWith(data._id));
        setUpdateUnit(removedUnit);

        // try {
        //   axios
        //     .delete(`${SERVER_URL}/${AXIOS_API_CALL.units}/${data._id}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.token}` } })
        //     .then((res) => {
        //       if (res.status === 200) {
        //         setRefetchUpdateUnitRemoved((prevState) => !prevState);
        //       }
        //     })
        //     .catch((err) => console.error(err))
        //     .finally(setTimeout(() => {}, 700));
        // } catch (err) {}

        break;
      default:
        console.warn('Default of: handleOnRemoveUnit function');
    }
  };

  /**
   * Toggle modal function
   * @param {*} props
   */
  const handleToggle = (props) => {
    const { name, data } = props;

    switch (name) {
      case 'create':
        setToggleCreate(true);
        break;
      case 'create-attribute-unit':
        setToggleCreateUnitAttribute(true);
        break;
      case 'create-unit':
        setToggleCreateUnit(true);
        setCreateUnitFormId({ id: data._id });
        break;
      case 'update':
        setToggleUpdate(true);
        setUpdateFormId({ id: data._id });
        break;
      case 'delete':
        setToggleDelete(true);
        setDeleteFormId({ id: data._id });
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  };

  /**
   * Handle on change function
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        const slug = slugify(value);
        setCreateForm({ ...createForm, name: value, slug: slug });
        break;
      case 'createSlug':
        const createSlug = slugifyReplaceAll(value);
        setCreateForm({ ...createForm, slug: createSlug });
        break;
      case 'createAttributeUnitName':
        setCreateUnitAttributeForm({ ...createUnitAttributeForm, name: value });
        break;
      case 'updateName':
        setUpdateForm({ ...updateForm, name: value });
        break;
      case 'updateSlug':
        const updateSlug = slugifyReplaceAll(value);
        setUpdateForm({ ...updateForm, slug: updateSlug });
        break;
      case 'updateCreateUnitName':
        setUpdateForm({ ...updateForm, unit: value });
        break;
      case 'createUnitName':
        setCreateUnitForm({ ...createUnitForm, name: value });
        break;
      default:
        console.warn('Default of: handleOnChange function!');
    }
  };

  /**
   * Handle on blur function
   * @param {*} event
   */
  const handleOnBlur = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, name: false });
        } else {
          toggleCreate ? setCreateFormError({ ...createFormError, name: true }) : setCreateFormError({ ...createFormError, name: false });
        }
        break;
      case 'createSlug':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, slug: false });
        } else {
          toggleCreate ? setCreateFormError({ ...createFormError, slug: true }) : setCreateFormError({ ...createFormError, slug: false });
        }
        break;
      case 'createAttributeUnitName':
        if (value.length >= 2) {
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        } else {
          toggleCreate && toggleCreateUnitAttribute ? setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true }) : setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        }
        break;
      case 'updateName':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          toggleUpdate ? setUpdateFormError({ ...updateFormError, name: true }) : setUpdateFormError({ ...updateFormError, name: false });
        }
        break;
      case 'updateSlug':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, slug: false });
        } else {
          toggleUpdate ? setUpdateFormError({ ...updateFormError, slug: true }) : setUpdateFormError({ ...updateFormError, slug: false });
        }
        break;
      case 'updateCreateUnitName':
        if (value.length >= 1) {
          setUpdateFormError({ ...updateFormError, unit: false });
        } else {
          toggleUpdate && toggleUpdateUnit ? setUpdateFormError({ ...updateFormError, unit: true }) : setUpdateFormError({ ...updateFormError, unit: false });
        }
        break;
      case 'createUnitName':
        if (value.length >= 1) {
          setCreateUnitFormError({ ...createUnitFormError, name: false });
        } else {
          toggleCreateUnit ? setCreateUnitFormError({ ...createUnitFormError, name: true }) : setCreateUnitFormError({ ...createUnitFormError, name: false });
        }
        break;
      default:
        console.warn('Default of: handleOnBlur function!');
    }
  };

  /**
   * Handle validate function
   * @param {name}
   * @param {form}
   */
  const handleOnValidate = ({ name, form }) => {
    let checkCase = false;

    let checkCreateName = false;
    let checkCreateSlug = false;

    let checkCreateAttributeUnitName = false;

    let checkCreateUnitName = false;

    let checkUpdateName = false;
    let checkUpdateSlug = false;

    let checkCreateUpdateUnit = false;

    switch (name) {
      case 'create':
        if (form.data.name.length >= 2) {
          checkCreateName = true;
          setCreateFormError({ ...createFormError, name: false });
        } else {
          checkCreateName = false;
          toggleCreate ? setCreateFormError({ ...createFormError, name: true }) : setCreateFormError({ ...createFormError, name: true });
        }

        if (form.data.slug.length >= 2) {
          checkCreateSlug = true;
          setCreateFormError({ ...createFormError, slug: false });
        } else {
          checkCreateSlug = false;
          toggleCreate ? setCreateFormError({ ...createFormError, slug: true }) : setCreateFormError({ ...createFormError, slug: true });
        }

        if (!checkCreateName && !checkCreateSlug) {
          setCreateFormError({ ...createFormError, name: true, slug: true });
        }

        if (checkCreateName && checkCreateSlug) {
          checkCase = true;
        }
        break;
      case 'create-attribute-unit':
        if (form.data.name.length >= 1) {
          checkCreateAttributeUnitName = true;
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        } else {
          checkCreateAttributeUnitName = false;
          toggleCreate ? setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true }) : setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        }

        if (!checkCreateAttributeUnitName) {
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true });
        }

        if (checkCreateAttributeUnitName) {
          checkCase = true;
        }
        break;
      case 'create-unit':
        if (form.data.name.length >= 1) {
          checkCreateUnitName = true;
          setCreateUnitFormError({ ...createUnitFormError, name: false });
        } else {
          checkCreateUnitName = false;
          toggleCreateUnit ? setCreateUnitFormError({ ...createUnitFormError, name: true }) : setCreateUnitFormError({ ...createUnitFormError, name: false });
        }

        if (!checkCreateUnitName) {
          setCreateUnitFormError({ ...createUnitFormError, name: true });
        }

        if (checkCreateUnitName) {
          checkCase = true;
        }
        break;
      case 'update':
        if (form.data.name.length >= 2) {
          checkUpdateName = true;
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          checkUpdateName = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, name: true }) : setUpdateFormError({ ...updateFormError, name: false });
        }

        if (form.data.slug.length >= 2) {
          checkUpdateSlug = true;
          setUpdateFormError({ ...updateFormError, slug: false });
        } else {
          checkUpdateSlug = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, slug: true }) : setUpdateFormError({ ...updateFormError, slug: false });
        }

        if (!checkUpdateName && !checkUpdateSlug) {
          setUpdateFormError({ ...updateFormError, name: true, slug: true });
        }

        if (checkUpdateName && checkUpdateSlug) {
          checkCase = true;
        }
        break;
      case 'update-create-unit':
        if (form.data.unit.length >= 1) {
          checkCreateUpdateUnit = true;
          setUpdateFormError({ ...updateFormError, unit: false });
        } else {
          checkCreateUpdateUnit = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, unit: true }) : setUpdateFormError({ ...updateFormError, unit: false });
        }

        if (!checkCreateUpdateUnit) {
          setUpdateFormError({ ...updateFormError, unit: true });
        }

        if (checkCreateUpdateUnit) {
          checkCase = true;
        }
        break;
      case 'delete':
        checkCase = true;
        break;
      default:
        console.warn('Default of: handleOnValidate function!');
    }

    return checkCase;
  };

  /**
   * Form submit function
   * @param {*} props
   */
  async function handleOnSubmit({ event, form }) {
    event.preventDefault();

    const { name } = event.target;

    const { token } = user;

    switch (name) {
      case 'create':
        setCreateBtnLoader(true);
        const createValidate = handleOnValidate({ name: name, form: form });

        if (createValidate) {
          let createPayload = {
            department: PERMISSIONS.grocery,
            name: createForm.name,
            units: [],
          };

          if (createUnitAttribute.length > 0) {
            const units = createUnitAttribute.map((unit) => unit._id);
            createPayload = {
              ...createPayload,
              units: units,
            };
          }

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.createAttribute}`, { ...createPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  setReadDataRefetch((prevState) => !prevState);
                  setCreateUnitAttribute([]);
                  form.onToggle(false);
                  form.onData({ name: '' });
                  form.formRef.current.reset();

                  // notification
                  notification.success({
                    message: notificationMessages.successCreateAttribute,
                    placement: 'bottomLeft',
                  });
                  setTimeout(() => {
                    setCreateBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateBtnLoader(false);
        }
        break;
      case 'create-attribute-unit':
        setCreateAttUnitBtnLoader(true);
        const createAttributeUnitValidate = handleOnValidate({ name: name, form: form });
        if (createAttributeUnitValidate) {
          const createAttributeUnitPayload = {
            value: createUnitAttributeForm.name,
            department: PERMISSIONS.grocery,
          };

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createAttributeUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  const newUnitData = {
                    _id: res.data.message._id,
                    value: res.data.message.value,
                  };

                  setCreateUnitAttribute([...createUnitAttribute, newUnitData]);
                  setToggleCreateUnitAttribute(false);
                  form.onData({ name: '' });
                  setTimeout(() => {
                    setCreateAttUnitBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateAttUnitBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateAttUnitBtnLoader(false);
        }
        break;
      case 'create-unit':
        setCreateUnitBtnLoader(true);
        const createUnitValidate = handleOnValidate({ name: name, form: form });

        if (createUnitValidate) {
          const createUnitID = createUnitFormId.id;

          const createUnitPayload = {
            value: createUnitForm.name,
            department: PERMISSIONS.grocery,
          };

          const fetchUnitData = await axios
            .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
              setTimeout(() => {
                setCreateUnitBtnLoader(false);
              }, 400);
              return res;
            })
            .catch((err) => {
              console.error(err);
              setCreateUnitBtnLoader(false);
            })
            .finally(setTimeout(() => {}, 700));

          let unitID = '';

          if (fetchUnitData.status === 201) {
            unitID = fetchUnitData.data.message._id;

            if (!!unitID) {
              await axios
                .put(`${SERVER_URL}/${AXIOS_API_CALL.addUnitToAttribute}/${createUnitID}`, { units: [unitID] }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
                .then((res) => {
                  if (res.status === 200) {
                    setReadDataRefetch((prevState) => !prevState);
                    form.onData({ name: '' });
                    form.onToggle(false);
                    form.formRef.current.reset();

                    // notification
                    notification.success({
                      message: res.data.message,
                      placement: 'bottomLeft',
                    });
                  }
                })
                .catch((err) => console.error(err))
                .finally(setTimeout(() => {}, 700));
            }
          }
        } else {
          setCreateUnitBtnLoader(false);
        }
        break;
      case 'update':
        setEditBtnLoader(true);
        const updateValidate = handleOnValidate({ name: name, form: form });

        if (updateValidate) {
          const updateID = updateFormId.id;

          let updatePayload = {
            name: updateForm.name,
            slug: updateForm.slug,
            department: PERMISSIONS.grocery,
            units: [],
          };

          if (updateUnit.length > 0) {
            updatePayload = {
              ...updatePayload,
              units: updateUnit,
            };
          }

          try {
            axios
              .put(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${updateID}`, { ...updatePayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setUpdateFormId({ id: '' });
                  setUpdateUnit([]);
                  setToggleUpdateUnit(false);
                  form.onData({ name: '', slug: '' });
                  form.onToggle(false);

                  notification.success({
                    message: res.data.message,
                    placement: 'bottomLeft',
                  });
                  setTimeout(() => {
                    setEditBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setEditBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setEditBtnLoader(false);
        }
        break;
      case 'update-create-unit':
        setCreateUnitBtnLoader(true);
        const updateCreateUnitValidate = handleOnValidate({ name: name, form: form });

        if (updateCreateUnitValidate) {
          const createUpdateUnitPayload = {
            value: updateForm.unit,
            department: PERMISSIONS.grocery,
          };

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createUpdateUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  const updateNewUnitData = {
                    _id: res.data.message._id,
                    value: res.data.message.value,
                  };

                  setUpdateUnit([...updateUnit, updateNewUnitData]);
                  setUpdateForm({ ...updateForm, unit: '' });
                  setToggleUpdateUnit(false);
                  setTimeout(() => {
                    setCreateUnitBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateUnitBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateUnitBtnLoader(false);
        }
        break;
      case 'delete':
        setDeleteBtnLoader(true);
        const deleteValidate = handleOnValidate({ name: name, form: form });

        if (deleteValidate) {
          const deleteID = deleteFormId.id;

          try {
            await axios
              .delete(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${deleteID}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setDeleteFormId({ id: '' });
                  form.onToggle(false);
                  form.formRef.current.reset();

                  notification.success({
                    message: res.data.message,
                    placement: 'bottomLeft',
                  });
                  // setDeleteBtnLoader(false);
                  setTimeout(() => {
                    setDeleteBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                setDeleteBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        } else {
          setDeleteBtnLoader(false);
        }
        break;
      default:
        console.warn('Default of: handleOnSubmit function!');
    }
  }

  const getUpdateAttribute = useCallback(
    (props) => {
      const { id } = props;

      const { token } = user;

      try {
        const response = axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setTimeout(() => {
              setUpdateLoadingForm(false);
            }, 700);
            return res;
          })
          .catch((err) => {});

        return response;
      } catch (err) {}
    },
    [user]
  );

  useEffect(() => {
    const updateID = updateFormId.id;

    let isMounted = true;

    if (!!updateID) {
      new Promise((resolve, reject) => {
        setUpdateLoadingForm(true);
        setTimeout(() => {
          resolve(getUpdateAttribute({ id: updateID }));
        }, 200);
      }).then((res) => {
        if (isMounted) {
          const responseUpdateData = {
            name: res.data.name,
            slug: res.data.name + '-slug',
          };

          const responseUnitData = res.data.units;

          setUpdateForm((prevState) => {
            return { ...prevState, ...responseUpdateData };
          });
          setUpdateUnit(responseUnitData);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [updateFormId, setUpdateForm, setUpdateUnit, getUpdateAttribute]);

  // CREATE ATTRIBUTE PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
    data: createForm,
    onData: setCreateForm,
  };

  // CREATE ATTRIBUTE UNIT PROPS
  const createAttributeUnitProps = {
    active: toggleCreateUnitAttribute,
    onToggle: setToggleCreateUnitAttribute,
    formRef: createAttributeUnitFormRef,
    data: createUnitAttributeForm,
    onData: setCreateUnitAttributeForm,
  };

  // CREATE UNIT PROPS
  const createUnitProps = {
    active: toggleCreateUnit,
    onToggle: setToggleCreateUnit,
    formRef: createUnitFormRef,
    data: createUnitForm,
    onData: setCreateUnitForm,
  };

  // UPDATE ATTRIBUTES PROPS
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    data: updateForm,
    onData: setUpdateForm,
    loading: updateLoadingForm,
  };

  // UPDATE CREATE UNIT
  const updateCreateUnitProps = {
    active: toggleUpdateUnit,
    onToggle: setToggleUpdateUnit,
    formRef: updateCreateUnitFormRef,
    data: updateForm,
    onData: setUpdateForm,
  };

  // DELETE PROPS
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData?.data?.pagesCount,
    totalCount: readData?.data?.totalCount,
  };

  // TABLE PROPS
  const tableProps = {};

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,

    createUnitProps: createUnitProps,
    updateProps: updateProps,
    deleteProps: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const createAttributesAndUnitProps = {
    createProps,
    createFormRef,
    handleOnSubmit,
    createForm,
    createFormError,
    handleOnChange,
    handleOnBlur,
    createUnitAttribute,
    handleOnRemoveUnit,
    toggleCreateUnitAttribute,
    setToggleCreateUnitAttribute,
    setToggleCreate,
    createBtnLoader,
    createAttributeUnitFormRef,
    createAttUnitBtnLoader,
    createUnitAttributeForm,
    createUnitAttributeFormError,
    createAttributeUnitProps,
  };

  const createUnitsProps = {
    createUnitProps,
    createUnitFormRef,
    handleOnSubmit,
    createUnitForm,
    createUnitFormError,
    handleOnBlur,
    handleOnChange,
    setToggleCreateUnit,
    createUnitBtnLoader,
  };

  const updateAttributesAndUnitProps = {
    updateProps,
    updateLoadingForm,
    updateFormRef,
    handleOnSubmit,
    updateFormError,
    updateForm,
    handleOnBlur,
    handleOnChange,
    updateUnit,
    handleOnRemoveUnit,
    toggleUpdateUnit,
    setToggleUpdateUnit,
    setToggleUpdate,
    updateCreateUnitFormRef,
    createUnitBtnLoader,
    editBtnLoader,
    updateCreateUnitProps,
  };

  const deleteAttributeProps = {
    deleteProps,
    handleOnSubmit,
    deleteFormRef,
    deleteBtnLoader,
    setToggleDelete,
  };

  return (
    <>
      <h2 className="page-title">Atributi</h2>
      <Section className="section__wrapper section__attributes">
        {/* Header */}
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Prikaži:</span>
              <SelectCustom options={options} label={'stavki'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Pretraži" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Pretraži
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
            <>
              <div className="actions">
                {!readDataLoading ? (
                  <button type="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-attribute-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Kreiraj Atribut</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '191px', height: '50px' }} />
                )}
              </div>
            </>
          )}
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData.data?.Data} {...tableProps} {...tableActionsProps} pagination={paginationProps} />}</Main>

        {/* Create Attributes and Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && <CreateAttributesAndUnit {...createAttributesAndUnitProps} />}

        {/* Create Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && <CreateUnit {...createUnitsProps} />}

        {/* Update Attributes and Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateAttributes) && <UpdateAttributesAndUnit {...updateAttributesAndUnitProps} />}

        {/* Delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteAttributes) && <DeleteAttribute {...deleteAttributeProps} />}
      </Section>
    </>
  );
};

export default Attributes;
