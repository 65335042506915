import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import GalleryUpload from './GalleryUpload';
import { Section, Main } from '../../../components/content';
import { PERMISSIONS } from '../../../utils/permissions';
import BannerImages from './BannerImages';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { ProductProvider } from '../../../context/products';

const Banner = () => {
  const { user } = useAuth();

  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [imageType, setImageType] = useState(null);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [refetch, setRefetch] = useState(false);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const [toggle, setToggle] = useState(false);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const url = search === null ? `${AXIOS_API_CALL.bannergalleryImages}` : `${AXIOS_API_CALL.galleryImagesSearch}` + search;
      const response = await axios
        .get(`${SERVER_URL}/${url}`, {
          withCredentials: false,
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);

          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, search]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  return (
    <ProductProvider>
      <h2 className="page-title">Slike za banere</h2>

      <Section className="section__wrapper section__gallery">
        <Main className="section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <>
              {toggle ? (
                <>
                  <GalleryUpload data={readData} setToggle={setToggle} imageType={imageType} setRefetch={setReadDataRefetch} />
                </>
              ) : null}

              <BannerImages data={readData} setToggle={setToggle} setImageType={setImageType} setRefetch={setReadDataRefetch} />
            </>
          )}
        </Main>
      </Section>
    </ProductProvider>
  );
};

export default Banner;
