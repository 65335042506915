import React, { useEffect, useState } from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';
import { Editor } from '@tinymce/tinymce-react';
import Loading from '../../../../components/loading/Loading';
import { getImageUrl } from '../../../../helpers/helpers';
const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;

export const UpdateCategory = ({ updateProps, loadingUpdate, updateFormRef, handleOnSubmit, createFormParent, updateForm, handleOnRemoveUpload, handleOnUploadFeatureImage, featureImageUpdateData, handleOnRemoveUploadFeatureImage, imageUpdateData, handleOnUpload, updateFormError, updateParentFormId, updateFormDropdown, attributes, updateDataAttribute, toggleUpdate, handleOnSelect, setSelected, setToggleUpdate, editBtnLoader, disabledUpdate, handleOnChange, handleOnBlur, isFeatured, setIsFeatured }) => {
  const [description, setDescription] = useState('');

  console.log(updateForm);

  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
      handleOnChange({ target: { name: 'updateDescription', value } });
    }
  };

  useEffect(() => {
    if (loadingUpdate) {
      handleInitial();
    }
  }, [updateForm]);

  const handleInitial = () => {
    setDescription(updateForm.description);
  };

  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Izmeni kategoriju">
      {!loadingUpdate ? (
        <form name="update" ref={updateFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: updateFormRef })}>
          <p>
            <b>Ikonica</b>
          </p>
          <div className="form-group upload-image">
            {!!updateForm.image && updateForm.image.length !== 0 ? (
              <>
                <div className="avatar">
                  <div className="image-wrapper">
                    <img src={getImageUrl(updateForm.image.url)} alt="Update category" data-cy="parent-update-category-image" className="img" />
                  </div>

                  <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'updateRemove' })}>
                    <span className="icon"></span>
                    <span className="text">Obriši sliku</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {imageUpdateData.blob ? (
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={imageUpdateData.blob} alt="Update category blob" data-cy="update-parent-new-image" className="img" />
                    </div>

                    <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'update' })}>
                      <span className="icon"></span>
                      <span className="text">Obriši sliku</span>
                    </button>
                  </div>
                ) : (
                  <div className="upload">
                    <div className="upload-wrapper d-flex gap-x-2">
                      <div>
                        <span className="text" data-cy="update-parent-image-placeholder">
                          <div className="text-center">
                            <p className="mb-0">Nema izabrane</p>
                            <p className="mb-0">slike</p>
                          </div>
                        </span>
                        <input
                          type="file"
                          name="updateImage"
                          id="updateUploadFile"
                          className="input-upload"
                          onChange={(event) =>
                            handleOnUpload({
                              name: event.target.name,
                              img: event.target.files[0],
                            })
                          }
                        />
                      </div>

                      <div className="validation-image">
                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageType ? 'error' : ''}`}>
                          * moguće je upload samo JPG/PNG sliku!
                        </p>

                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageSize ? 'error' : ''}`}>
                          * Slika mora biti manja od 2MB!
                        </p>
                      </div>
                    </div>
                    <label htmlFor="updateUploadFile" data-cy="update-parent-upload-btn" className="label btn btn-primary-outline btn-no-hover">
                      <span className="icon">
                        <img src="/assets/icons/upload.svg" alt="Update category" className="img" />
                      </span>
                      <span className="text">Objavi sliku</span>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>
          <p>
            <b>Slika kategorije</b>
          </p>
          <div className="form-group upload-image">
            {!!updateForm.featureImage && updateForm.featureImage.length !== 0 ? (
              <>
                <div className="avatar">
                  <div className="image-wrapper">
                    <img src={getImageUrl(updateForm.featureImage.url)} alt="Update category" data-cy="parent-update-category-image" className="img" />
                  </div>

                  <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUploadFeatureImage({ name: 'updateRemove' })}>
                    <span className="icon"></span>
                    <span className="text">Obriši sliku</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {featureImageUpdateData.blob ? (
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={featureImageUpdateData.blob} alt="Update category blob" data-cy="update-parent-new-image" className="img" />
                    </div>

                    <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUploadFeatureImage({ name: 'update' })}>
                      <span className="icon"></span>
                      <span className="text">Obriši sliku</span>
                    </button>
                  </div>
                ) : (
                  <div className="upload">
                    <div className="upload-wrapper d-flex gap-x-2">
                      <div>
                        <span className="text" data-cy="update-parent-image-placeholder">
                          <div className="text-center">
                            <p className="mb-0">Nema izabrane</p>
                            <p className="mb-0">slike</p>
                          </div>
                        </span>
                        <input
                          type="file"
                          name="updateImage"
                          id="updateUploadFileSecond"
                          className="input-upload"
                          onChange={(event) =>
                            handleOnUploadFeatureImage({
                              name: event.target.name,
                              img: event.target.files[0],
                            })
                          }
                        />
                      </div>

                      <div className="validation-image">
                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageType ? 'error' : ''}`}>
                          * moguće je upload samo JPG/PNG sliku!
                        </p>

                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageSize ? 'error' : ''}`}>
                          * Slika mora biti manja od 2MB!
                        </p>
                      </div>
                    </div>
                    <label htmlFor="updateUploadFileSecond" data-cy="update-parent-upload-btn" className="label btn btn-primary-outline btn-no-hover">
                      <span className="icon">
                        <img src="/assets/icons/upload.svg" alt="Update category" className="img" />
                      </span>
                      <span className="text">Objavi sliku</span>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateName">
              <b className="required">Naziv:</b>
            </label>

            <input name="updateName" id="updateName" defaultValue={updateForm.updateName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy={createFormParent.parent ? 'update-name-category' : 'update-name-subcategory'} className={`input ${updateFormError.updateName || updateFormError.uniqueName ? 'input-error' : ''}`} />

            <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? '' : updateFormError.updateName ? 'error' : ''}`}>
              * Ovo polje je obavezno!
            </p>
            <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? 'error' : ''}`}>
              * Ovaj naziv kategorije već postoji!
            </p>
          </div>
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateSlug">
              <b className="required">Slug:</b>
            </label>

            <input name="updateSlug" id="updateSlug" value={updateForm.updateSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-update-input" className={`input ${updateFormError.updateSlug || updateFormError.uniqueSlug ? 'input-error' : ''}`} />

            <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? '' : updateFormError.updateSlug ? 'error' : ''}`}>
              * Ovo polje je obavezno!
            </p>
            <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? 'error' : ''}`}>
              * Ovaj slug već postoji!
            </p>
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="seoTitle">
              <b>Seo title:</b>
            </label>
            <input name="seoTitle" id="seoTitle" value={updateForm.seoTitle || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Seo title" data-cy="google-description-update-input" className="input" />
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateGoogleDescription">
              <b>Google opis:</b>
            </label>
            <input name="updateGoogleDescription" id="updateGoogleDescription" value={updateForm.updateGoogleDescription || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Google opis" data-cy="google-description-update-input" className="input" />
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateKeywords">
              <b>Ključne reči:</b>
            </label>
            <input name="updateKeywords" id="updateKeywords" value={updateForm.updateKeywords || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Klučne reči" data-cy="keywords-update-input" className="input" />
          </div>

          {/* createFormParent.parent */}

          <div className="form-group">
            <label className="label mb-1 d-block" htmlFor="parentCategory">
              <b>Roditeljska kategorija:</b>
            </label>

            <select className="select select-full" id="parentCategory" name="parentCategory" defaultValue={updateParentFormId && !!updateParentFormId.id ? updateParentFormId.id : 'none'} onChange={handleOnChange}>
              <option value="none">Nema</option>
              {updateFormDropdown &&
                updateFormDropdown.length > 0 &&
                updateFormDropdown.map((item, idx) => {
                  return (
                    <option key={idx} value={item._id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group">
            <label className="label mb-1 d-block" htmlFor="createKeywords">
              <b>Opis:</b>
            </label>
            <div className="select select-result">
              <Editor
                apiKey={apiKey}
                name="description"
                onEditorChange={(newValue, editor) => {
                  getTextValue(editor.getContent());
                }}
                init={{
                  height: 600,
                  menubar: true,
                  plugins: 'table link',
                  entity_encoding: 'raw',
                }}
                value={description}
              />
            </div>
          </div>
          {createFormParent.parent && (
            <div className="featured-cat-wrap">
              <input
                type="checkbox"
                name="createFeatured"
                id="createFeatured"
                required={false}
                onChange={(event) => {
                  setIsFeatured((prev) => !prev);
                }}
                checked={isFeatured}
              />
              <label className="label mb-1 d-block" htmlFor="createFeatured">
                <b>Istaknuta kategorija</b>
              </label>
            </div>
          )}
          <div className="form-group form-group-sidebar">
            <button data-cy="modal_update_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
              <span className="text">Otkaži</span>
            </button>

            {!editBtnLoader ? (
              <button data-cy="modal_update_submit-btn" type="submit" className="btn btn-primary" disabled={disabledUpdate}>
                <span className="text">Sačuvaj</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">Čuvanje...</span>
              </button>
            )}
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
