import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useBlogs } from '../../../../../context/blogs';
import axios from 'axios';
import { SERVER_URL } from '../../../../../config/index';

export const BlogCategories = (props) => {
  const { form, setForm, productCategoriesData, setProductDropdownOptions, productDropdownOptions } = useBlogs();
  const { className } = props;

  const [value, setValue] = useState({ tags: '' });

  const getProductsByCategory = async (value) => {
    const res = await axios.post(`${SERVER_URL}/get-products-by-categories`, { categories: value });
    const data = await res.data;

    // if data is already exist in array then remove it
    const newData = data.filter((item) => !productDropdownOptions?.some((item2) => item._id === item2._id));

    if (data && data.length > 0) {
      setProductDropdownOptions((prevState) => {
        return [...prevState, ...newData];
      });
    } else {
      setProductDropdownOptions([]);
    }
  };

  const getInitialProductsDropdownData = async () => {
    if (form.blogCategories && form.blogCategories.length > 0) {
      const res = await axios.post(`${SERVER_URL}/get-products-by-categories`, { categories: form.blogCategories });
      const data = await res.data;

      if (data && data.length > 0) {
        setProductDropdownOptions(data);
      }
    }
  };

  useEffect(() => {
    getInitialProductsDropdownData();
  }, [form]);

  const onChange = (event) => {
    const value = event;
    getProductsByCategory(value);

    setForm((prevState) => {
      return {
        ...prevState,
        blogCategories: [...value],
      };
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const isValid = onValid(value);

    if (isValid) {
      setValue({ tags: '' });
    }
  };

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header">
        <h4>Kategorije:</h4>
      </header>

      <main className="card-content">
        <form name="product-tags" onSubmit={onSubmit} className="form">
          <div className="form-group">
            <Select mode="multiple" value={form.blogCategories} onChange={onChange} showArrow name="brand" placeholder="Kategorije" type="text" id="tag" style={{ width: '100%' }}>
              {productCategoriesData &&
                productCategoriesData.length > 0 &&
                productCategoriesData.map((item, idx) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </form>
      </main>
    </Wrapper>
  );
};
