import React, { useEffect } from 'react';
import { Section, Col, Row, Wrapper } from '../../../../components/content';
import { ReferencesProvider, UseReferences } from '../../../../context/references';
import { PERMISSIONS } from '../../../../utils/permissions';
// import { BlogBreadcrumb } from './blog-breadcrumb/BlogBreadcrumb';
import ReferenceGallery from './reference-gallery/ReferenceGallery';
import { ReferenceInfo } from './reference-info/ReferenceInfo';
import { ReferenceCategories } from './reference-categories/ReferenceCategories';
// import { BlogType } from './blog-type/BlogType';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import Loading from '../../../../components/loading/Loading';
// import { BlogProducts } from './blog-products/BlogProducts';

const CreateBlogLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = UseReferences();

  useEffect(() => {
    setProductEdit(false);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const CreateReferenceSubmit = () => {
  const { form, onCancel, onSubmit, productCategoriesLoading } = UseReferences();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Otkazati</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onSubmit(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.references}`)} className="btn btn-primary" type="button">
            <span className="text">Kreiraj Referencu</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const CreateReference = () => {
  return (
    <ReferencesProvider>
      {/* <BlogBreadcrumb /> */}
      <Section className="section__product section__product--wrapper section__product--create product-form">
        <CreateBlogLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <ReferenceInfo className="product__card--info" />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <ReferenceGallery className="product__card--gallery" />
                {/* <ProductType className="product__card--type" /> */}
                {/* <BlogOptions className="product__card--options" /> */}
                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <ReferenceCategories className="product__card--tags" />
                {/* <Declaration className="product-description" /> */}
                {/* <BlogProducts className="product__card--tags" /> */}
              </Wrapper>
            </Col>
          </Row>
        </CreateBlogLoader>

        <CreateReferenceSubmit />
      </Section>
    </ReferencesProvider>
  );
};

export default CreateReference;
