import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';

const ProductUpcCode = ({ className }) => {
  const { form, onChange } = useProducts();

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header">
        <h4>UPC Kod Proizvoda</h4>
      </header>
      <main className="card-content">
        <form name="product-upc-code" className="form">
          <div className="form-group">
            <label className="label" htmlFor="productUpcCode">
              <b>UPC Kod:</b>
            </label>
            <input onChange={onChange} name="productUpcCode" type="text" placeholder="UPC Kod" id="productUpcCode" className="input" value={form.productUpcCode} />
          </div>
        </form>
      </main>
    </Wrapper>
  );
};

export default ProductUpcCode;
