import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const DeleteForm = ({ deleteProps, deleteModalFormRef, handleOnFinish, deleteFormFields, deleteBtnLoader, setToggleDelete }) => {
  return (
    <Modal {...deleteProps} className="sidebar__modal--center" title="">
      <Form name="delete" ref={deleteModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'delete', data: data })} fields={deleteFormFields}>
        <h2 className="text-center mb-4">Da li ste sigurni da želite da izbrišete izabrani brand ?</h2>

        <Form.Item name="deleteBrandId" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deleteBtnLoader ? (
              <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                <span>Da</span>
              </Button>
            ) : (
              <Button type="button" className="btn btn-primary-link btn-block">
                <span>Brisanje...</span>
              </Button>
            )}

            <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleDelete(false)}>
              <span>Otkazati</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
