import React, { useEffect } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { ReferencesProvider, UseReferences } from '../../../../context/references';
import Loading from '../../../../components/loading/Loading';
import { ReferenceInfo } from '../create-reference/reference-info/ReferenceInfo';
// import { BlogCreationInfo } from '../create-blog/blog-creation-info/BlogCreationInfo';
import { ReferenceCategories } from '../create-reference/reference-categories/ReferenceCategories';
// import { BlogProducts } from '../create-blog/blog-products/BlogProducts';
import { PERMISSIONS } from '../../../../utils/permissions';
import ReferenceGallery from '../create-reference/reference-gallery/ReferenceGallery';
// import { BlogBreadcrumb } from './reference-breadcrumb/ReferenceBreadcrumbs';

const UpdateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit, imagesArray } = UseReferences();

  useEffect(() => {
    setProductEdit(true);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const UpdateProductSubmit = () => {
  const { form, onCancel, onUpdate, productCategoriesLoading } = UseReferences();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Otkazati</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.references}`)} className="btn btn-primary" type="button">
            <span className="text">Izmeni Referencu</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const UpdateBlog = () => {
  return (
    <ReferencesProvider>
      {/* <BlogBreadcrumb /> */}
      <Section className="section__product section__product--wrapper section__update-product product-form">
        <UpdateProductLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <ReferenceInfo className="product__card--info" />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                {/* <BlogCreationInfo className="product__card--tags" /> */}
                <ReferenceGallery className="product__card--gallery" />
                {/* <ProductType className="product__card--type" /> */}

                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <ReferenceCategories className="product__card--tags" />
                {/* <Declaration className="product-description" /> */}
                {/* <BlogProducts className="product__card--tags" /> */}
              </Wrapper>
            </Col>
          </Row>
        </UpdateProductLoader>
        <UpdateProductSubmit />
      </Section>
    </ReferencesProvider>
  );
};

export default UpdateBlog;
