import React from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';

const Table = (props) => {
  const { data } = props;
  let subtotal = 0;
  let subTotalTest = 0;

  const isProductImage = (item) => {
    const isCheckFeaturedImage = item && item.hasOwnProperty('featureImage') && item.featureImage !== null;

    if (isCheckFeaturedImage) {
      // const featureName = item && item.featureImage.originalname;
      const featureImage = item && item.featureImage && item.featureImage.url;

      return <img src={featureImage} />;
    }
    return <img src="/assets/images/product-placeholder.svg" style={{ width: '50px', objectFit: 'contain', margin: '0 auto' }} />;
  };

  // detalji porudzbenice
  const fullName = data && data.userData && data.userData[0].userFullName;
  const deliveryAddress = data && data.address && `${data.address[0].address}, ${data.address[0].city}`;
  const userEmail = data && data.userData && data.userData[0].userEmailAddress;
  const userPhoneNumber = data && data.userData && data.userData[0].userPhoneNumber;
  const orderingAs = data && data.orderingAs;
  const deliveryPicked = data && data.deliveryType;
  const contactPerson = (data && data.contactPerson) || '';
  const pib = (data && data.pib) || '';
  const companyId = (data && data.companyIdNumber) || '';
  const companyName = (data && data.companyName) || '';
  const comment = (data && data.commentCompany) || '';

  // loop kroz proizvode i sabiranje cene
  subTotalTest = data && data.order && data.order.map((item) => Number(item.totalPrice));
  const subTotalTest1 = subTotalTest && subTotalTest?.reduce((a, b) => a + b, 0);

  const subTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subTotalTest1);

  return (
    <>
      <div className="order-details--top">
        <p>
          <strong>Način naručivanja:</strong> {orderingAs && orderingAs === 'fizicko' ? 'Fizičko lice' : orderingAs === 'pravno' ? 'Pravno lice' : ''}
        </p>
        <p>
          <strong>Način dostave:</strong> {deliveryPicked && deliveryPicked === 'kurirska' ? 'Kurirska služba' : deliveryPicked === 'licno' ? 'Lično preuzimanje' : ''}
        </p>
        <p>
          <strong>Ime i prezime:</strong> {fullName}
        </p>
        <p>
          <strong>Adresa dostave:</strong> {deliveryAddress}
        </p>
        <p>
          <strong>Email:</strong> {userEmail}
        </p>
        <p>
          <strong>Broj telefona:</strong> {userPhoneNumber}
        </p>
        {orderingAs && orderingAs === 'pravno' ? (
          <div>
            <p>
              <strong>Kontakt osoba:</strong> {contactPerson}
            </p>
            <p>
              <strong>PIB:</strong> {pib}
            </p>
            <p>
              <strong>Matični broj:</strong> {companyId}
            </p>
            <p>
              <strong>Naziv firme:</strong> {companyName}
            </p>
            <p>
              <strong>Komentar:</strong> {comment}
            </p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Proizvodi</th>
            <th>Količina</th>
            {/* <th>Address Delivery</th> */}
            {/* <th>Shipping</th> */}
            {/* <th>Payment</th> */}
            {/* <th>Status</th> */}
            <th>Cena</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.order.length > 0 ? (
            data?.order.map((item, idx) => {
              subtotal = subtotal + Number(item.totalPrice);
              const address = data.address[0].address + ', ' + data.address[0].city + ', ' + data.address[0].state;

              const itemTotalPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.totalPrice).replace('$', '');
              // const subTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subtotal);
              return (
                <tr key={item._id}>
                  <td>
                    <div className="product-item">
                      {/* <img src="/assets/images/fake-image.png" alt={item.title} /> */}
                      {isProductImage(item)}
                      <span>{item.title}</span>
                    </div>
                  </td>
                  <td>{item.quantity}</td>
                  {/* <td>{data.address.length > 0 ? (data.address[0] === 'Split Shipping' ? item.deliveryAddress : address) : ''}</td> */}
                  {/* <td>{item.status}</td> */}
                  {/* <td>{data.paymentType}</td> */}
                  {/* <td>{data.status}</td> */}
                  <td>{itemTotalPrice}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
          {data && data.order.length > 0 ? (
            <tr className="subtotal-row">
              <td style={{ textAlign: 'right', paddingRight: '30px' }} colSpan={7}>
                Ukupno: {subTotal.replace('$', '')} RSD
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
};

export default Table;
