import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';

const Table = (props) => {
  // TABLE
  const { data = [], handleCheckedRows, handleDisabledButton, handleToggle } = props;

  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  const handleUpdateClick = async (brandId) => {
    try {
      setLoadingBrand(true);

      const brandDataResponse = await axios.get(`${SERVER_URL}/brands/${brandId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const brandData = brandDataResponse.data;

      setUpdateBrand(brandData);

      handleToggle({ name: 'update', state: updateActions, data: brandId });

      setLoadingBrand(false);
    } catch (err) {
      setLoadingBrand(false);
      console.error(err);
    }
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Naziv</th>
            <th>Slug</th>
            <th>Opis</th>
            <th>Akcija</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data.data.Data && data.data.Data && data.data.Data.length > 0 ? (
            data.data.Data?.map((item) => {
              let maxLength = 80;
              const description = item.description;

              const showMoreText = description.length > maxLength ? ` +${description.length - maxLength}` : '';

              const truncatedDescription = (
                <>
                  {description.length > maxLength ? (
                    <>
                      <span>{description.substring(0, maxLength).replace(/(<([^>]+)>)/gi, '')}</span>
                      <span style={{ border: '1px solid #d9d9d9', padding: '1px 3px', marginLeft: '10px' }}>{showMoreText}</span>
                    </>
                  ) : (
                    description.replace(/(<([^>]+)>)/gi, '')
                  )}
                </>
              );
              return (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>{item.slug}</td>
                  <td>{truncatedDescription}</td>

                  <td className="y-top">
                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateBrands) && (
                      <button className="btn btn-actions" style={{ border: '1px solid #D9D9D9', borderRadius: '5px', height: '30px', width: '30px' }} type="button" data-cy="actions-update-attributes" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })}>
                        <span className="icon">
                          <img alt="Update" src="/assets/icons/edit-silver.svg" />
                        </span>
                        <span className="text">
                          <strong></strong>
                        </span>
                      </button>
                    )}
                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteBrands) && (
                      <button className="btn btn-actions" style={{ marginLeft: '15px', border: '1px solid #D9D9D9', borderRadius: '5px', height: '30px', width: '30px' }} type="button" data-cy="actions-delete-brand" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
                        <span className="icon">
                          <img alt="Delete" src="/assets/icons/delete.svg" />
                        </span>
                        <span className="text">
                          <strong></strong>
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
