import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { TabsPanel } from './tabs-panel/TabsPanel';
// Tabs component

import { General } from './general/General';

import { useEffect } from 'react';

export const ProductOptions = (props) => {
  const { form, tabs, setTabs } = useProducts();
  const { className } = props;

  let tabsContent = [];

  if (form.productType === 'simple') {
    tabsContent = [
      // { id: 'dfjhsdfhshfsdjfsdfjkk', name: 'Inventory', element: <Inventory /> },
      { id: 'fsdfsdkfdfdkkdkdkdkkd', name: 'Cene', element: <General /> },
    ];
  }

  if (form.productType === 'variables') {
    tabsContent = [
      // { id: 'dfjhsdfhshfsdjfsdfjkk', name: 'Inventory', element: <Inventory /> },
      // { id: 'dffdfdsfdfsdfdioweifo', name: 'Variations', element: <Variations {...props} /> },
    ];
  }

  useEffect(() => {
    if (form.productType) {
      setTabs(0);
    }
  }, [form.productType, setTabs]);

  return (
    <Wrapper className={`product__card ${className}`}>
      <TabsPanel {...props} tabsContent={tabsContent} tabs={tabs} setTabs={setTabs} />
    </Wrapper>
  );
};
