import { Wrapper } from '../../../../../components/content';
import { BlogProvider, useBlogs } from '../../../../../context/blogs';

export const BlogCreationInfo = () => {
  const { form } = useBlogs();
  console.log('form', form);

  const createdBy = (form?.blogCreatedBy && `${form?.blogCreatedBy?.firstName} ${form?.blogCreatedBy?.lastName}`) || 'N/A';
  const createdAt = (form?.blogCreatedAt && new Date(form?.blogCreatedAt).toLocaleDateString('sr-RS', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })) || 'N/A';
  return (
    <Wrapper className={`product__card`}>
      <header className="card-header" style={{ padding: '20px', borderBottom: '1px solid #d9d9d9' }}>
        <h4 style={{ marginBottom: 0 }}>Informacije:</h4>
      </header>

      <main className="card-content">
        <p>
          <span>
            <strong>Kreirao:</strong>
          </span>{' '}
          {createdBy}
        </p>
        <p>
          <span>
            <strong>Datum/Vreme:</strong>
          </span>{' '}
          {createdAt}
        </p>
      </main>
    </Wrapper>
  );
};
