import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import Modal from '../../../components/modal/Modal';
import { Col, Row, Wrapper } from '../../../components/content';
import { useAuth } from '../../../context/useAuth';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification, Select } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Loading from '../../../components/loading/Loading';
import { useNavigate } from 'react-router-dom';

const extansionRemoved = (str) => {
  if (str) {
    return str.replace(/.png|.jpg|.jpeg|.gif|.svg/g, '');
  } else {
    console.warn('Problem with string of undefined');
    return;
  }
};

const BannerImages = (props) => {
  const { data, setRefetch, setToggle, setImageType } = props;
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();
  const refCurrent = useRef(null);
  const [toggleImage, setToggleImage] = useState(false);
  const [brands, setBrands] = useState([]);
  const [featuredBrand, setFeaturedBrand] = useState(null);
  const [toggleImageLoader, setToggleImageLoader] = useState(false);
  const [imageId, setImageId] = useState({ id: '' });
  const [imageData, setImageData] = useState({});
  const [imageDataLoading, setImageDataLoading] = useState(false);
  const [featuredImageError, setFeaturedImageError] = useState(false);

  useEffect(() => {
    fetchBrands();
    fetchFeaturedBrand();
  }, []);

  const fetchBrands = async () => {
    const res = await axios.post(
      `${SERVER_URL}/brands`,
      { department: 'grocery' },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setBrands(res.data.items);
  };

  const fetchFeaturedBrand = async () => {
    const res = await axios.post(
      `${SERVER_URL}/featured-brand`,
      { department: 'grocery' },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res && res.data && res.data.item) {
      setFeaturedBrand(res.data.item._id);
    }
  };

  const handleOnToggle = (id) => {
    setImageId({ id: id });
    setToggleImage(true);
  };

  const handleChangeFeaturedBrand = async (e) => {
    try {
      await axios.put(
        `${SERVER_URL}/featured-brand/${e}`,
        { department: 'grocery' },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setFeaturedBrand(e);

      notification.success({ message: 'Uspešno promenjen istaknuti brend.' });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error,
      });
    }
  };

  const getImageById = useCallback(
    async (image) => {
      const id = image.id;

      if (id && id.length > 0) {
        setImageDataLoading(true);
        await axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.bannergetImagesById}/${id}`)
          .then((res) => {
            if (res.status === 200) {
              setImageData(res?.data);
            }
          })
          .catch((err) => {
            console.error(err);
            setImageDataLoading(false);
          })
          .finally(() => {
            setTimeout(() => {
              setImageDataLoading(false);
            }, 700);
          });
      }
    },
    [user]
  );

  useEffect(() => {
    getImageById(imageId);
  }, [imageId]);

  const getDateFormatMDY = (date) => {
    const d = new Date(date);
    const monthAndDay = d.toLocaleString('default', { month: 'long', day: 'numeric' });
    const year = d.getFullYear();

    return `${monthAndDay}, ${year}`;
  };

  const [form, setForm] = useState({
    id: '',
    imageName: '',
    imageAlt: '',
    bannerUrlRedirect: '',
  });

  useEffect(() => {
    if (imageId.id !== '' && Object.keys(imageData).length > 0) {
      const form = {
        id: imageId.id,
        imageName: extansionRemoved(imageData.originalname),
        imageAlt: imageData.altTitle,
        bannerUrlRedirect: imageData.bannerUrlRedirect,
      };

      setForm(form);
    }
  }, [imageId, imageData, setForm]);

  const onChange = (event) => {
    const { name, value } = event.target;

    if (name === 'featured') {
      setForm((prevState) => {
        return { ...prevState, [name]: !prevState.featured };
      });
      return;
    }

    setForm((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const id = form.id;
    const token = user.token;

    let payload = {
      department: PERMISSIONS.grocery,
      title: form.imageName,
      bannerUrlRedirect: form.bannerUrlRedirect,
    };

    if (typeof form.imageAlt !== 'undefined' && form.imageAlt !== null) {
      payload = {
        ...payload,
        altTitle: form.imageAlt,
        bannerUrlRedirect: form.bannerUrlRedirect,
      };
    }

    try {
      setToggleImageLoader(true);
      await axios
        .put(`${SERVER_URL}/${AXIOS_API_CALL.bannerupdateGalleryImage}/${id}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setRefetch((p) => !p);
            setToggleImage(false);
            setImageId({ id: '' });
            setImageData({});
            setToggleImageLoader(false);

            // notification
            notification.success({
              message: res.data.message,
              placement: 'bottomLeft',
            });
          }
        })
        .catch((err) => {
          setFeaturedImageError(true);
          setToggleImageLoader(false);
        })
        .finally(() => {
          setToggleImageLoader(false);
        });
    } catch (err) {
      console.log('err', err.message);
    }
  };

  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const onDelete = async () => {
    const id = form.id;
    const token = user.token;

    try {
      setDeleteBtnLoader(true);
      await axios
        .delete(`${SERVER_URL}/${AXIOS_API_CALL.bannerdeleteGalleryImage}/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setImageId({ id: '' });
            setToggleImage(false);
            setImageData({});
            setRefetch((p) => !p);
            setDeleteBtnLoader(false);

            // notifications
            notification.success({
              message: res.data.message,
              placement: 'bottomLeft',
            });
          }
        })
        .catch((err) => {
          setDeleteBtnLoader(false);
          console.error(err);
        })
        .finally(() => {
          setDeleteBtnLoader(false);
        });
    } catch (err) {
      console.log('err', err.message);
    }
  };

  // order data by featured items first
  const sortedData = data.sort((a, b) => {
    return b.featured - a.featured;
  });

  const horizontalBannerDesktop1 = sortedData.filter((item) => item.bannerType === 'banner-horizontal-desktop-1');
  const horizontalBannerMobile1 = sortedData.filter((item) => item.bannerType === 'banner-horizontal-mobile-1');
  const horizontalBannerDesktop2 = sortedData.filter((item) => item.bannerType === 'banner-horizontal-desktop-2');
  const horizontalBannerMobile2 = sortedData.filter((item) => item.bannerType === 'banner-horizontal-mobile-2');
  const bannerVerticalDesktop = sortedData.filter((item) => item.bannerType === 'banner-vertical-desktop');
  const bannerVerticalMobile = sortedData.filter((item) => item.bannerType === 'banner-vertical-mobile');

  return (
    <>
      <Wrapper className="gallery-images banner-images-wrapper" style={{ position: 'relative' }}>
        <div className="refCurrent" ref={refCurrent} style={{ position: 'absolute', top: '-100px', left: '0' }}></div>
        <div className="item">
          <div className="item-wrapper">
            <p>Horizontalni baner 1</p>
            <p>Desktop</p>
            {horizontalBannerDesktop1 && horizontalBannerDesktop1.length > 0 ? (
              horizontalBannerDesktop1.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-horizontal" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                    {/* <div className="card" onClick={() => handleOnToggle(item._id)} style={{ backgroundImage: `url(${item?.url})`, backgroundSize: 'cover' }}> */}
                    {/* </div> */}
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-horizontal"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-horizontal-desktop-1');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
                {/* <img style={{ cursor: 'pointer' }} className="img-horizontal" src="/assets/images/upload-placeholder.png" alt="upload placeholder" onClick={() => {
                  setImageType('banner-horizontal-desktop-1');
                  setToggle((t) => !t)
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }} /> */}
              </Col>
            )}
            {horizontalBannerDesktop1.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-horizontal-desktop-1');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{horizontalBannerDesktop1.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
          <div className="item-wrapper">
            <p>Mobile</p>
            {horizontalBannerMobile1 && horizontalBannerMobile1.length > 0 ? (
              horizontalBannerMobile1.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-horizontal-mobile" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                    {/* <div className="card card-mobile-horizontal" onClick={() => handleOnToggle(item._id)} style={{ backgroundImage: `url(${item?.url})`, backgroundSize: 'cover' }}>
                    </div> */}
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-horizontal-mobile"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-horizontal-mobile-1');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
                {/* <img style={{ cursor: 'pointer' }} className="img-horizontal-mobile" src="/assets/images/upload-placeholder-mobile.png" alt='upload mobile placeholder' onClick={() => {
                  setImageType('banner-horizontal-mobile-1');
                  setToggle((t) => !t)
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }} /> */}
              </Col>
            )}
            {horizontalBannerMobile1.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-horizontal-mobile-1');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{horizontalBannerMobile1.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
        </div>
        <div className="item">
          <div className="item-wrapper">
            <p>Horizontalni baner 2</p>
            <p>Desktop</p>
            {horizontalBannerDesktop2 && horizontalBannerDesktop2.length > 0 ? (
              horizontalBannerDesktop2.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-horizontal" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-horizontal"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-horizontal-desktop-2');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
              </Col>
            )}
            {horizontalBannerDesktop2.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-horizontal-desktop-2');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{horizontalBannerDesktop2.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
          <div className="item-wrapper">
            <p>Mobile</p>
            {horizontalBannerMobile2 && horizontalBannerMobile2.length > 0 ? (
              horizontalBannerMobile2.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-horizontal-mobile img-horizontal-mobile--second" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-horizontal-mobile img-horizontal-mobile--second"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-horizontal-mobile-2');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
                {/* <img style={{ cursor: 'pointer' }} className="img-horizontal-mobile" src='/assets/images/upload-placeholder-mobile.png' alt='upload placeholder mobile' onClick={() => {
                  setImageType('banner-horizontal-mobile-2');
                  setToggle((t) => !t)
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }} /> */}
              </Col>
            )}
            {horizontalBannerMobile2.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-horizontal-mobile-2');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{horizontalBannerMobile2.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
        </div>
        <div className="item">
          <div className="item-wrapper">
            <p>Vertikalni baner</p>
            <div className="form-group">
              <label className="label" htmlFor="brand">
                <p>Istaknuti brend:</p>
              </label>

              <Select
                value={featuredBrand}
                onChange={(e) => {
                  handleChangeFeaturedBrand(e);
                }}
                name="brand"
                placeholder="Istaknuti brend"
                type="text"
                id="brand"
                style={{ width: '100%' }}
              >
                {brands &&
                  brands.length > 0 &&
                  brands.map((item, index) => {
                    return <Option value={item._id}>{item.name}</Option>;
                  })}
              </Select>
            </div>
            <p>Desktop</p>
            {bannerVerticalDesktop && bannerVerticalDesktop.length > 0 ? (
              bannerVerticalDesktop.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-vertical" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-vertical"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-vertical-desktop');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
                {/* <img style={{ cursor: 'pointer' }} className="img-vertical" src="/assets/images/upload-vertical-placeholder.png" alt="upload placeholder" onClick={() => {
                  setImageType('banner-vertical-desktop');
                  setToggle((t) => !t)
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }} /> */}
              </Col>
            )}
            {bannerVerticalDesktop.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-vertical-desktop');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{bannerVerticalDesktop.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
          <div className="item-wrapper">
            <p>Mobile</p>
            {bannerVerticalMobile && bannerVerticalMobile.length > 0 ? (
              bannerVerticalMobile.map((item) => {
                return (
                  <Col key={item._id}>
                    <img style={{ cursor: 'pointer' }} className="img-vertical-mobile" onClick={() => handleOnToggle(item._id)} src={item.url} alt={item.originalname} />
                  </Col>
                );
              })
            ) : (
              <Col>
                <div
                  className="img-vertical-mobile"
                  style={{ border: '1px dashed #d9d9d9', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', fontSize: '12px', cursor: 'pointer' }}
                  onClick={() => {
                    setImageType('banner-vertical-mobile');
                    setToggle((t) => !t);
                    refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <img src="/assets/icons/upload-image.svg" alt="Upload" />
                </div>
                {/* <img style={{ cursor: 'pointer' }} className="img-vertical-mobile" src="/assets/images/upload-placeholder-mobile.png" alt="upload placeholder" onClick={() => {
                  setImageType('banner-vertical-mobile');
                  setToggle((t) => !t)
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }} /> */}
              </Col>
            )}
            {bannerVerticalMobile.length > 0 && (
              <button
                onClick={() => {
                  setImageType('banner-vertical-mobile');
                  setToggle((t) => !t);
                  refCurrent.current.scrollIntoView({ behavior: 'smooth' });
                }}
                className="btn btn-primary pl-2 pr-2"
                type="button"
              >
                <span className="text">{bannerVerticalMobile.length > 0 ? 'Izmeni sliku' : 'Dodaj sliku'}</span>
              </button>
            )}
          </div>
        </div>
        {/* <Row>
          {horisontalBanner1 &&
            horisontalBanner1.length > 0 &&
            horisontalBanner1.map((item) => {
              return (
                <Col key={item._id}>
                  <div className="card" onClick={() => handleOnToggle(item._id)}>
                    {item.featured && (<div className="featured-flag">Prva slika</div>)}
                    <img className="img" src={item.url} alt={item.originalname} />
                  </div>
                </Col>
              );
            })}
        </Row>
        <div className="actions">
          <button onClick={() => {
            setImageType('banner-horizontal-desktop-1');
            setToggle((t) => !t)
          }} className="btn btn-primary pl-2 pr-2" type="button">
            <span className="text">{horisontalBanner1.length > 0 ? 'Izmeni' : 'Dodaj'}</span>
          </button>
          <button onClick={() => {
            setImageType('banner-horizontal-desktop-2');
            setToggle((t) => !t)
          }} className="btn btn-primary pl-2 pr-2" type="button">
            <span className="text">Dodaj horizaontalni baner 2</span>
          </button>
        </div> */}
      </Wrapper>

      <Modal active={toggleImage} onToggle={setToggleImage} setFeaturedImageError={setFeaturedImageError} title="Detalji banera" className="gallery-view modal-full">
        <div className="view-layout relative">
          {!imageDataLoading ? (
            <>
              <div className="content-image relative">{imageData ? <img src={imageData?.url} /> : null}</div>

              <div className="content-info relative">
                <div className="information">
                  <p>
                    <b>Uvezeno:</b>
                    <span> {imageData && imageData?.updatedAt ? getDateFormatMDY(imageData?.updatedAt) : null}</span>
                  </p>
                  {/* <p>
                    <b>File name:</b>
                    <span> {imageData && imageData?.originalname ? extansionRemoved(imageData?.originalname) : null}</span>
                  </p>
                  <p>
                    <b>File url:</b>
                    <a href={imageData.url}> {imageData && imageData.url}</a>
                  </p> */}
                </div>

                <form
                  onSubmit={(event) => {
                    onSubmit(event);
                  }}
                  name="update-image"
                  className="form form-update-images"
                >
                  {/* <div className="form-group">
                    <label className="label" htmlFor="imageName">
                      <b>Image name:</b>
                    </label>

                    <input value={form.imageName || ''} onChange={onChange} name="imageName" id="imageName" className="input input-full" type="text" placeholder="Image Name" />
                  </div> */}

                  {/* <div className="form-group">
                    <label className="label" htmlFor="imageAlt">
                      <b>Image alt:</b>
                    </label>

                    <input value={form.imageAlt || ''} onChange={onChange} name="imageAlt" id="imageAlt" className="input input-full" type="text" placeholder="Image Alt" />
                  </div> */}

                  {/* add checkbox to make image featured*/}
                  <div className="form-group">
                    <label className="label" htmlFor="imageAlt">
                      <b>Url za redirekciju:</b>
                    </label>

                    <input value={form.bannerUrlRedirect || ''} onChange={onChange} name="bannerUrlRedirect" id="bannerUrlRedirect" className="input input-full" type="text" placeholder="Url za redirekciju" />
                  </div>

                  <div className="form-group">
                    <div className="actions">
                      {toggleImageLoader || deleteBtnLoader ? (
                        <button className="btn btn-primary pl-2 pr-2" type="button">
                          <span className="text">Saving...</span>
                        </button>
                      ) : (
                        <button className="btn btn-primary pl-2 pr-2" type="submit">
                          <span className="text">Sačuvaj izmene</span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                <div className="information">
                  <div className="actions">
                    {toggleImageLoader || deleteBtnLoader ? (
                      <button type="button" className="btn btn-danger">
                        <span className="text">Brisanje...</span>
                      </button>
                    ) : (
                      <button onClick={() => onDelete()} type="button" className="btn btn-danger">
                        <span className="text">Izbrisati</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </Modal>
    </>
  );
};

export default BannerImages;
