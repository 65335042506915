import { useProducts } from '../../context/products';

const InputRadioField = (props) => {
  const { idx, checked, setChecked } = props;
  const { onChangeItem } = useProducts();
  const { isChecked } = checked;

  return (
    <div className="form-group">
      <label className="label">
        <input onChange={onChangeItem} checked={isChecked === idx} onClick={() => setChecked({ isChecked: idx })} value={idx} name="singleSelect" id={`singleSelect-${idx}`} type="radio" />
        <b>Izaberi</b>
      </label>
    </div>
  );
};

export default InputRadioField;
