import { notification, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useBlogs } from '../../../../../context/blogs';
import axios from 'axios';
import { SERVER_URL } from '../../../../../config/index';

export const BlogProducts = (props) => {
  const { form, setForm, productCategoriesData, setProductDropdownOptions, productDropdownOptions } = useBlogs();
  const { className } = props;

  const [value, setValue] = useState({ tags: '' });

  const onChange = (event) => {
    const value = event;
    // getProductsByCategory(value);

    setForm((prevState) => {
      return {
        ...prevState,
        blogProducts: [...value],
      };
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const isValid = onValid(value);

    if (isValid) {
      setValue({ tags: '' });
    }
  };

  // const onRemove = (item) => {
  //   const newTags = form.productTags.filter((t) => t !== item);
  //   setForm((prevState) => {
  //     return { ...prevState, blogCategories: newTags };
  //   });
  // };

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header">
        <h4>Preporučeni proizvodi:</h4>
      </header>

      <main className="card-content">
        <form name="product-tags" onSubmit={onSubmit} className="form">
          <div className="form-group">
            <Select mode="multiple" value={form.blogProducts} onChange={onChange} showArrow name="brand" placeholder="Proizvodi" type="text" id="tag" style={{ width: '100%' }}>
              {productDropdownOptions &&
                productDropdownOptions.length > 0 &&
                productDropdownOptions.map((item, idx) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.title}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </form>
      </main>
    </Wrapper>
  );
};
