import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import Loading from '../../../../components/loading/Loading';

export const UpdateAttributesAndUnit = ({ updateProps, updateLoadingForm, updateFormRef, handleOnSubmit, updateFormError, updateForm, handleOnBlur, handleOnChange, updateUnit, handleOnRemoveUnit, toggleUpdateUnit, setToggleUpdateUnit, setToggleUpdate, updateCreateUnitFormRef, createUnitBtnLoader, editBtnLoader, updateCreateUnitProps }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Izmeni Atribut">
      {!updateLoadingForm ? (
        <>
          <form name="update" ref={updateFormRef} className="ant-form ant-form-horizontal mb-2" onSubmit={(event) => handleOnSubmit({ event: event, form: updateProps })}>
            <div className="form-group relative">
              <label className="label mb-1 d-block" htmlFor="updateName">
                <b className="required">Naziv:</b>
              </label>

              <input type="text" placeholder="Name" name="updateName" id="updateName" data-cy="update-attribute-name-input" value={updateForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.name ? 'input-error' : ''}`} />

              <p data-cy="modal-update-attribute-name-error" className={`error__onblur ${updateFormError.name ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div>
              <label className="label mb-1 d-block">
                <b>Vrednosti:</b>
              </label>
            </div>

            <div className="form-group form-group-units relative">
              <div className="units" data-cy="removed-update-attribute-unit-btn">
                {updateUnit && updateUnit.length > 0 ? (
                  updateUnit.map((item) => {
                    return (
                      <div className="unit-item" key={item._id} data-cy="update-unit-item-btn" onClick={() => handleOnRemoveUnit({ name: 'update-attribute-unit', data: item })}>
                        <span className="icon">
                          <img src="/assets/icons/times-small.svg" alt="Close" />
                        </span>
                        <span className="text">{item.value}</span>
                      </div>
                    );
                  })
                ) : (
                  <p className="units-area">Units</p>
                )}
              </div>

              {toggleUpdateUnit ? (
                <button type="button" className="btn btn-default" data-cy="toggle-update-minus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(false)}>
                  <span className="icon">
                    <img alt="Create new Unit" src="/assets/icons/minus.svg" />
                  </span>
                </button>
              ) : (
                <button type="button" className="btn btn-default" data-cy="toggle-update-plus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(true)}>
                  <span className="icon">
                    <img alt="Create new Unit" src="/assets/icons/plus.svg" />
                  </span>
                </button>
              )}
            </div>

            {!toggleUpdateUnit && (
              <div className="form-group form-group-sidebar">
                <button data-cy="modal-update-attribute-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
                  <span className="text">Otkazati</span>
                </button>

                {!editBtnLoader ? (
                  <button data-cy="modal-update-attribute-unit-submit-btn" type="submit" className="btn btn-primary">
                    <span className="text">Sačuvaj izmene</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Čuvanje...</span>
                  </button>
                )}
              </div>
            )}
          </form>

          {/* Create Unit in Attribute in Update */}
          {toggleUpdateUnit && (
            <form name="update-create-unit" ref={updateCreateUnitFormRef} className="ant-form ant-form-horizontal mb-2 p-2" onSubmit={(event) => handleOnSubmit({ event: event, form: updateCreateUnitProps })} style={{ backgroundColor: '#f2f2f2', borderRadius: '6px' }}>
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateCreateUnitName">
                  <b className="required">Naziv:</b>
                </label>

                <input type="text" placeholder="Naziv" name="updateCreateUnitName" id="updateCreateUnitName" value={updateForm.unit || ''} data-cy="update-create-attribute-unit-name-input" onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.unit ? 'input-error' : ''}`} />

                <p data-cy="modal-update-create-unit-name-error" className={`error__onblur ${updateFormError.unit ? 'error' : ''}`}>
                  * Ovo polje je obavezno!
                </p>
              </div>

              <div className="form-group form-group-sidebar">
                <button data-cy="modal-update-create-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdateUnit(false)}>
                  <span className="text">Otkazati</span>
                </button>

                {!createUnitBtnLoader ? (
                  <button data-cy="modal-update-create-unit-submit-btn" type="submit" className="btn btn-primary">
                    <span className="text">Kreiraj vrednost</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Kreiranje...</span>
                  </button>
                )}
              </div>
            </form>
          )}
        </>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
