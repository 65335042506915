import React, { useEffect } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import GalleryModal from '../../../gallery-page/GalleryModal';

export const ProductGallery = (props) => {
  const {
    toggleGallery,
    setToggleGallery,
    gallerySelect,
    setGallerySelect,
    tabsGallery,
    setTabsGallery,
    refetchGallery,
    setRefetchGallery,

    select,
    setSelect,
    setForm,
    onChangeItem,

    selected,
    setSelected,
    onChangeItems,

    reviewImages,
    getImagesByIds,
    onRemoveSelected,
    selectImagesId,
    setFeaturedImage,
    form,
    productEdit,
    featuredImage,
  } = useProducts();

  const { className } = props;

  const handleToggleGallery = () => {
    setToggleGallery((prevState) => !prevState);
    setGallerySelect('multiselect');
  };

  const galleryModalProps = {
    toggleGallery,
    setToggleGallery,
    gallerySelect,
    setGallerySelect,
    tabsGallery,
    setTabsGallery,
    refetchGallery,
    setRefetchGallery,

    select,
    setSelect,
    setForm,
    onChangeItem,

    selected,
    setSelected,
    onChangeItems,

    reviewImages,
    getImagesByIds,
    onRemoveSelected,
    selectImagesId,
    setFeaturedImage,
    form,
    productEdit,
  };

  useEffect(() => {
    if (selectImagesId && selectImagesId.length > 0) {
      getImagesByIds(selectImagesId);
    } else {
      getImagesByIds([]);
    }
  }, [selected, selectImagesId]);

  useEffect(() => {
    if (reviewImages && reviewImages.length > 0) {
      const galleryImages = reviewImages.map((item) => {
        return item._id;
      });

      setForm((prevState) => {
        return { ...prevState, productGallery: galleryImages };
      });
    }
  }, [reviewImages]);

  return (
    <>
      <Wrapper className={`product__card ${className}`}>
        <header className="card-header">
          <h4>Galerija Slika Proizvoda</h4>
        </header>
        <main className="card-content">
          {reviewImages && reviewImages.length > 0 ? (
            <div className="images-area">
              <div className="images-selected">
                {reviewImages.map((item) => {
                  return (
                    <div
                      className="images"
                      key={item._id}
                      onClick={(e) => {
                        if (e.target.className === 'actions') {
                          setFeaturedImage(item._id);
                        }
                      }}
                    >
                      <img alt={item.originalname} src={item.url} className={`img ${reviewImages[0]._id === item._id ? 'feature-image' : ''} `} />
                      <div className="actions">
                        <button type="button" className="btn" onClick={() => onRemoveSelected(item._id)}>
                          <span className="icon">
                            <img src="/assets/icons/delete.svg" alt="Delete" className="img" />
                          </span>
                        </button>
                      </div>
                    </div>
                  );
                })}
                <button className="btn btn-select-images" type="button" onClick={() => handleToggleGallery()}>
                  <span className="text">Uvezi Slike</span>
                </button>
              </div>
              <span className="legend">
                <span className="capsule"></span>
                Istaknuta slika
              </span>
              <span style={{ fontSize: 14 }}>Klik na drugu sliku da bi bila istaknuta</span>
            </div>
          ) : (
            <button className="btn btn-select-images" type="button" onClick={() => handleToggleGallery()}>
              <span className="text">Uvezi Slike</span>
            </button>
          )}
          {reviewImages.length > 0 && <span></span>}
        </main>
      </Wrapper>

      <GalleryModal {...galleryModalProps} />
    </>
  );
};
