import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';

export const UpdateFormAllSettings = ({ updateProps, loadingEmployee, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Uredi korisnika">
      {!loadingEmployee ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
          <Form.Item
            name="firstName"
            label="Ime:"
            rules={[
              {
                required: true,
                message: 'Unesite vaše ime!',
              },
            ]}
          >
            <Input placeholder="Ime:" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Prezime:"
            rules={[
              {
                required: true,
                message: 'Unesite vaše prezime!',
              },
            ]}
          >
            <Input placeholder="Prezime:" />
          </Form.Item>

          <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Unesite vaš email!' }]}>
            <Input placeholder="Email:" value="" />
          </Form.Item>

          {/* <Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
            <Select>
              <Option value="">Select State</Option>

              {stateUS.map((item) => {
                return (
                  <Option key={item.name} value={item.abbreviation}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          {/* <Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
            <Input placeholder="Address:" />
          </Form.Item> */}

          {/* <Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
            <Input placeholder="City:" />
          </Form.Item> */}

          {/* <Form.Item
            name="zip_code"
            label="Zip Code:"
            rules={[
              {
                required: false,
                message: 'Please input your zip code!',
              },
            ]}
          >
            <Input placeholder="Zip Code:" />
          </Form.Item> */}

          {/* <Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
            <Input placeholder="Phone:" />
          </Form.Item> */}

          <Form.Item className="initial-height">
            <Checkbox onChange={onChange} checked={toggleNewPassword}>
              Kreiraj novu lozinku
            </Checkbox>
          </Form.Item>

          {toggleNewPassword === true && (
            <Form.Item
              name="newPassword"
              label="Lozinka:"
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: 'Unesite vašu lozinku!',
                },
              ]}
            >
              <Input.Password placeholder="Lozinka:" />
            </Form.Item>
          )}

          <Form.Item name="role" label="Izaberite ulogu:" rules={[{ required: true, message: 'Izaberite ulogu!' }]}>
            <Select>
              <Option value="">Izaberite ulogu</Option>

              {roles.map((item) => {
                return (
                  <Option key={item.name} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="updateUserId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Otkazati</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Sačuvaj izmene</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Uredjivanje...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
