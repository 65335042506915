import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteProduct = ({ deleteProps, onSubmit, setToggleDelete, deleteFormId }) => {
  return (
    <Modal {...deleteProps} className="sidebar__modal--center" title="">
      <form name="delete" onSubmit={(event) => onSubmit({ event: event, name: 'delete', data: deleteFormId })}>
        <h2 className="text-center mb-4">Da li ste sigurni da želite da izbrišete izabrani proizvod ?</h2>

        <div className="form-group form-group-modal mb-4">
          <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
            <span className="text">Da</span>
          </button>

          <button data-cy="modal-delete-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
            <span className="text">Otkazati</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
