import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, Button, notification } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import jwt_decode from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { CreateForm } from './create-form/CreateForm';
import { UpdateFormAllSettings } from './update-form/UpdateFormAllSettings';
import { DeleteForm } from './delete-form/DeleteForm';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const Declarations = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(1000);
  const [list, setList] = useState({ data: { Data: { items: [] } } });
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [imageUpdateData, setImageUpdateData] = useState({
    upload: [],
    image: [],
    blob: '',
  });
  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);

  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const getTableData = useCallback(async () => {
    const { token } = user;

    setLoading(true);
    try {
      await axios
        .post(`${SERVER_URL}/declarations/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setList({ data: res?.data });
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  // ----------------------- IMAGE ----------------------
  const [imageData, setImageData] = useState({
    upload: [],
    image: [],
    blob: '',
  });

  // ------------------ ERROR HANDLING ------------------
  const [createFormError, setCreateFormError] = useState({
    imageSize: false,
    imageType: false,
  }); // check

  const [updateFormError, setUpdateFormError] = useState({
    imageSize: false,
    imageType: false,
  }); // check

  function handleOnUpload(props) {
    const { name, img } = props;

    const isJpgOrPng = img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png';
    const isLt2M = img.size / 1024 / 1024 < 2;

    if (name === 'createImage') {
      if (isJpgOrPng && isLt2M) {
        setImageData({
          ...imageData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setCreateFormError({
          ...createFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setCreateFormError({ ...createFormError, imageType: true });
      }

      if (!isLt2M) {
        setCreateFormError({ ...createFormError, imageSize: true });
      }
    }

    if (name === 'updateImage') {
      if (isJpgOrPng && isLt2M) {
        setImageUpdateData({
          ...imageUpdateData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setUpdateFormError({
          ...updateFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setUpdateFormError({ ...updateFormError, imageType: true });
      }

      if (!isLt2M) {
        setUpdateFormError({ ...updateFormError, imageSize: true });
      }
    }
  }

  function handleOnRemoveUpload(props) {
    const { name } = props;

    if (name === 'create') {
      setCreateBrand((prevData) => ({
        ...prevData,
        image: null,
      }));
      setImageData({
        ...imageData,
        upload: null,
        blob: null,
      });
      setCreateFormError({
        ...createFormError,
        imageSize: false,
        imageType: false,
      });
    }
    if (name === 'update') {
      setUpdateBrand((prevData) => ({
        ...prevData,
        image: null,
      }));
      setImageUpdateData({
        ...imageUpdateData,
        upload: null,
        blob: null,
      });
    }

    if (name === 'updateRemove') {
      setUpdateBrand((prevForm) => ({
        ...prevForm,
        image: null,
      }));
    }
  }

  async function getUploadImage(props) {
    const { name } = props;
    let data = [];

    const { token } = user;

    if (name === 'create') {
      if (imageData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageData?.upload);

        if (typeof imageData?.upload === 'object') {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/declarations/',
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                data = res.data.image;
                setImageData({ ...imageData, image: res.data.image });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    if (name === 'update') {
      if (imageUpdateData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageUpdateData?.upload);

        if (typeof imageUpdateData?.upload === 'object') {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/brands/',
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                data = res.data.image;
                setImageUpdateData({
                  ...imageUpdateData,
                  image: res.data.image,
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    return data;
  }

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage]);

  useEffect(() => {
    getTableData();
  }, [getTableData, currentPage, limitPage, search, filterStatus]);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  // Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  const [brandTestUpdateId, setBrandTestUpdateId] = useState({
    id: '',
  });
  const [brandDeleteId, setBrandDeleteId] = useState('');

  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'create':
        state.onToggle((prevState) => !prevState);
        break;
      case 'update':
        setBrandTestUpdateId({ id: data });
        state.onToggle((prevState) => !prevState);
        break;
      case 'delete':
        setBrandDeleteId(data);
        state.onToggle((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // Refs
  const createModalFormRef = useRef(null);
  const updateModalFormRef = useRef(null);
  const deleteModalFormRef = useRef(null);

  // State
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);

  // Store
  const [createBrand, setCreateBrand] = useState([]);
  const [updateBrand, setUpdateBrand] = useState([]);

  const [deleteBrand, setDeleteBrand] = useState([]);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);

  const onChange = (e) => {
    if (e.target.checked) {
      setToggleNewPassword(true);
    } else {
      setToggleNewPassword(false);
    }
  };

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const [loadingBrand, setLoadingBrand] = useState(false);

  // FETCH UPDATE DATA
  useEffect(() => {
    const brandUpdateId = brandTestUpdateId.id;
    const { token } = user;

    if (!!brandUpdateId) {
      try {
        setLoadingBrand(true);

        const declarationUpdateroute = `/declaration/${brandUpdateId}`;
        axios
          .get(
            `${SERVER_URL}${declarationUpdateroute}`,

            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setUpdateBrand(res.data.data);
            setTimeout(() => {
              setLoadingBrand(false);
            }, 700);
          })
          .catch((err) => {
            setLoadingBrand(false);
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, brandTestUpdateId, refetch, setUpdateBrand]);

  const handleOnFinish = async (props) => {
    const { name, data } = props;

    const { token } = user;

    switch (name) {
      case 'create':
        try {
          let fetchData = await axios.post(`${SERVER_URL}/declarations`, data, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (fetchData.status === 201) {
            setRefetch((prevState) => !prevState);
            setCurrentPage(1);
            setToggleCreate(false);
            setCreateBrand([]);

            createModalFormRef.current.resetFields();
          }

          notification.success({
            message: 'Uspešno kreirana deklaracija.',
            placement: 'bottomLeft',
          });
          setTimeout(() => {
            setCreateBtnLoader(false);
            window.location.reload();
          }, 700);
        } catch (err) {
          setCreateBtnLoader(false);
          console.error(err);
          notification.error({
            message: notificationMessages.brandAlreadyExists,
            placement: 'bottomLeft',
          });
        }
        break;
      case 'update':
        setEditBtnLoader(true);
        try {
          const declarationId = props.id;

          const brandUpdatedUrl = `/declaration/${declarationId}`;
          const brandUpdateResponse = await axios.put(`${SERVER_URL}${brandUpdatedUrl}`, data, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (brandUpdateResponse.request.status === 200) {
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleUpdate(false);
          setUpdateBrand([]);
          setBrandTestUpdateId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
          }, 750);

          notification.success({
            message: 'Uspešno izmenjena deklaracija.',
            placement: 'bottomLeft',
          });

          setTimeout(() => {
            window.location.reload();
          }, 700);
        } catch (err) {
          console.error(err);
          setEditBtnLoader(false);
        }
        break;
      case 'delete':
        try {
          const declarationDelete = `/declaration/${brandDeleteId}`;
          const deleteBrandResponse = await axios.delete(`${SERVER_URL}${declarationDelete}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleDelete(false);
          setDeleteBrand([]);
          setBrandDeleteId('');

          deleteModalFormRef.current.resetFields();

          notification.success({
            message: notificationMessages.successDeleteBrand,
            placement: 'bottomLeft',
          });
          setTimeout(() => {
            setDeleteBtnLoader(false);
          }, 700);
        } catch (err) {
          notification.error({
            message: notificationMessages.failedDeleteBrand,
            placement: 'bottomLeft',
          });
          setDeleteBtnLoader(false);
        }

        break;

      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // Create Brand
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    data: createBrand,
    onData: setCreateBrand,
  };

  // Update Brand
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: updateBrand,
    onData: setUpdateBrand,
    loading: loadingBrand,
  };

  // Delete Brand
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
    data: deleteBrand,
    onData: setDeleteBrand,
  };

  // TABLE
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,

    updateActions: updateProps,
    deleteActions: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list.data?.pagesCount,
    totalCount: list.data?.totalCount,
  };

  const createFormFields = [
    {
      name: ['role'],
      value: '',
    },
    {
      name: ['state'],
      value: '',
    },
    {
      name: 'createUserId',
      value: user.id,
    },
    {
      name: 'createUserType',
      value: 'dashboard',
    },
  ];

  const updateFormFields = [
    {
      name: 'updateBrandId',
      value: updateBrand._id,
    },
    {
      name: 'name',
      value: updateBrand.name,
    },
    {
      name: 'slug',
      value: updateBrand.slug,
    },
    {
      name: 'description',
      value: updateBrand.description,
    },
    {
      name: 'googleDescription',
      value: updateBrand.googleDescription,
    },
    {
      name: 'keywords',
      value: updateBrand.keywords,
    },
  ];

  const deleteFormFields = [
    {
      name: 'deleteBrandId',
      value: brandDeleteId,
    },
  ];

  const createFormProps = {
    createProps,
    createModalFormRef,
    handleOnFinish,
    createFormFields,
    createBtnLoader,
    setToggleCreate,
    imageData,
    handleOnRemoveUpload,
    handleOnUpload,
    createFormError,
  };

  const updateFormAllSettingsProps = {
    updateProps,
    loadingBrand,
    updateModalFormRef,
    updateFormFields,
    editBtnLoader,
    handleOnFinish,
    setToggleUpdate,
    imageData,
    imageUpdateData,
    handleOnRemoveUpload,
    handleOnUpload,
    createFormError,
    updateFormError,
  };

  const deleteFormProps = {
    deleteProps,
    deleteModalFormRef,
    handleOnFinish,
    deleteFormFields,
    deleteBtnLoader,
    setToggleDelete,
  };

  return (
    <>
      <h2 className="page-title">Deklaracije</h2>

      <Section className="section section__wrapper section__brands">
        {/* Main Header */}
        <header className="section__header">
          <div className="left">{!loading ? <h3 className="mb-0">Deklaracije</h3> : <LoadingPlaceholder style={{ width: '94.5px', height: '50px' }} />}</div>
          <div className="search">
            <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
              <div className="form-group m-0">
                <span className="icon icon-search">
                  <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                </span>
                <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Pretraži" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                  <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                </span>
              </div>
              <button className="form-submit" data-cy="search-submit-btn">
                Pretraži
              </button>
            </form>
          </div>

          <div className="actions right">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createBrands) && (
              <>
                {!loading ? (
                  <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-brand-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Kreiraj deklaraciju</span>
                  </Button>
                ) : (
                  <LoadingPlaceholder style={{ width: '180.5px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
        {/* Create Brand - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createBrands) && <CreateForm {...createFormProps} />}

        {/* Update Brand - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateBrands) && <UpdateFormAllSettings {...updateFormAllSettingsProps} />}

        {/* Delete Brand - delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteBrands) && <DeleteForm {...deleteFormProps} />}
      </Section>
    </>
  );
};

export default Declarations;
