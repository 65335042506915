import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useProducts } from '../../../../../../context/products';

const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;
export const Declaration = (props) => {
  const { form, setForm, productEdit } = useProducts();
  const { label, className } = props;

  const [declaration, setDeclaration] = useState('');
  const getTextValue = (value) => {
    if (value !== declaration) {
      setDeclaration(value);
    }
  };

  useEffect(() => {
    if (declaration && declaration.length > 0) {
      setForm((prevState) => {
        return { ...prevState, productDeclaration: declaration };
      });
    }
  }, [declaration, setForm]);

  useEffect(() => {
    if (productEdit && form.productDeclaration !== '') {
      setDeclaration(form.productDeclaration);
    }
  }, []);

  return (
    <div className="custom-select">
      <span className="label">
        <p>Deklaracija proizvoda :</p>
      </span>

      <div className="select select-result">
        <Editor
          apiKey={apiKey}
          onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
          init={{
            height: 600,
            menubar: true,
            entity_encoding: 'raw',
            plugins: 'table link',
          }}
          value={declaration}
        />
      </div>
    </div>
  );
};
