import React, { useState, useEffect } from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';
import { Editor } from '@tinymce/tinymce-react';

const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;

export const CreateParentCategory = ({ createProps, createFormRef, handleOnSubmit, imageData, handleOnRemoveUploadFeatureImage, featureImageData, handleOnRemoveUpload, handleOnUpload, handleOnUploadFeatureImage, createFormError, createForm, handleOnChange, handleOnBlur, attributes, toggleCreate, handleOnSelect, setToggleCreate, createBtnLoader, disabledCreate, setSelected, isFeatured, setIsFeatured, setCreateForm }) => {
  const [description, setDescription] = useState('');
  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (!createProps.active) {
      setCreateForm({});
      setDescription('');
    }
  }, [createProps.active]);

  return (
    <SidebarModal {...createProps} className="sidebar__modal--right form-category" title="Kreiraj Kategoriju">
      <form name="create" ref={createFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: createFormRef })}>
        <p>
          <b>Ikonica</b>
        </p>
        <div className={`form-group upload-image`}>
          {imageData.blob ? (
            <div className="avatar">
              <div className="image-wrapper">
                <img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
              </div>

              <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                <span className="icon" data-cy="image-placeholder"></span>
                <span className="text">Izbriši Fotografiju</span>
              </button>
            </div>
          ) : (
            <div className="upload">
              <div className="upload-wrapper d-flex gap-x-2">
                <div>
                  <span className="text">
                    <div className="text-center">
                      <p className="mb-0">Nema izabrane</p>
                      <p className="mb-0">fotografije</p>
                    </div>
                  </span>
                  <input
                    type="file"
                    name="createImage"
                    id="uploadFile"
                    className="input-upload"
                    onChange={(event) =>
                      handleOnUpload({
                        name: event.target.name,
                        img: event.target.files[0],
                      })
                    }
                  />
                </div>

                <div className="validation-image">
                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                    * Možete uvesti samo JPG/PNG file!
                  </p>

                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                    * Fotografija mora biti manja od 2MB!
                  </p>
                </div>
              </div>
              <label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                <span className="icon">
                  <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                </span>
                <span className="text">Objavi sliku</span>
              </label>
            </div>
          )}
        </div>
        <p>
          <b>Slika kategorije</b>
        </p>
        <div className={`form-group upload-image`}>
          {featureImageData.blob ? (
            <div className="avatar">
              <div className="image-wrapper">
                <img src={featureImageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
              </div>

              <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUploadFeatureImage({ name: 'create' })}>
                <span className="icon" data-cy="image-placeholder"></span>
                <span className="text">Izbriši Fotografiju</span>
              </button>
            </div>
          ) : (
            <div className="upload">
              <div className="upload-wrapper d-flex gap-x-2">
                <div>
                  <span className="text">
                    <div className="text-center">
                      <p className="mb-0">Nema izabrane</p>
                      <p className="mb-0">fotografije</p>
                    </div>
                  </span>
                  <input
                    type="file"
                    name="createImage"
                    id="uploadFileSecond"
                    className="input-upload"
                    onChange={(event) => {
                      console.log(event);
                      handleOnUploadFeatureImage({
                        name: event.target.name,
                        img: event.target.files[0],
                      });
                    }}
                  />
                </div>

                <div className="validation-image">
                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                    * Možete uvesti samo JPG/PNG file!
                  </p>

                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                    * Fotografija mora biti manja od 2MB!
                  </p>
                </div>
              </div>
              <label htmlFor="uploadFileSecond" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                <span className="icon">
                  <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                </span>
                <span className="text">Objavi sliku</span>
              </label>
            </div>
          )}
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createName">
            <b className="required">Naziv:</b>
          </label>

          <input name="createName" id="createName" value={createForm.createName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-input" className={`input ${createFormError.createName || createFormError.uniqueName ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? '' : createFormError.createName ? 'error' : ''}`}>
            * Ovo polje je obavezno!
          </p>
          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? 'error' : ''}`}>
            * Ovaj naziv kategorije već postoji!
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSlug">
            <b className="required">Slug:</b>
          </label>

          <input name="createSlug" id="createSlug" value={createForm.createSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-input" className={`input ${createFormError.createSlug || createFormError.uniqueSlug ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createFormError.uniqueSlug ? '' : createFormError.createSlug ? 'error' : ''}`}>
            * Ovo polje je obavezno!
          </p>
          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueSlug ? 'error' : ''}`}>
            * Ovaj naziv za slug već postoji!
          </p>
        </div>
        <div className="form-group">
          <label className="label mb-1 d-block" htmlFor="seoTitle">
            <b>Seo title:</b>
          </label>
          <input name="seoTitle" id="seoTitle" value={createForm.seoTitle || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Seo title" data-cy="google-description-create-input" className="input" />
        </div>
        <div className="form-group">
          <label className="label mb-1 d-block" htmlFor="createGoogleDescription">
            <b>Google opis:</b>
          </label>
          <input name="createGoogleDescription" id="createGoogleDescription" value={createForm.createGoogleDescription || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Google opis" data-cy="google-description-create-input" className="input" />
        </div>

        <div className="form-group">
          <label className="label mb-1 d-block" htmlFor="createKeywords">
            <b>Ključne reči:</b>
          </label>
          <input name="createKeywords" id="createKeywords" value={createForm.createKeywords || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Ključne reči" data-cy="keywords-create-input" className="input" />
        </div>
        <div className="form-group">
          <label className="label mb-1 d-block" htmlFor="createKeywords">
            <b>Opis:</b>
          </label>
          <div className="select select-result">
            <Editor
              apiKey={apiKey}
              name="description"
              onEditorChange={(newValue, editor) => {
                getTextValue(editor.getContent());
                handleOnChange({ target: { name: 'description', value: editor.getContent() } });
              }}
              init={{
                height: 600,
                menubar: true,
                plugins: 'table link',
                entity_encoding: 'raw',
              }}
              value={createForm.description}
            />
          </div>
        </div>
        <div className="featured-cat-wrap">
          <input
            type="checkbox"
            name="createFeatured"
            id="createFeatured"
            required={false}
            onChange={(event) => {
              setIsFeatured((prev) => !prev);
              // onChange(event);
            }}
            checked={isFeatured}
          />
          <label className="label mb-1 d-block" htmlFor="createFeatured">
            <b>Istaknuta kategorija</b>
          </label>
        </div>
        <div className="form-group form-group-sidebar">
          <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
            <span className="text">Otkaži</span>
          </button>

          {!createBtnLoader ? (
            <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreate}>
              <span className="text">Kreiraj Kategoriju</span>
            </button>
          ) : (
            <button type="button" className="btn  btn-primary-outline">
              <span className="text">Kreiranje...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
