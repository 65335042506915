export const TabsContent = (props) => {
  const { tabs, tabsContent } = props;

  return (
    <div className="tabs__panel-content">
      {tabsContent.map((item, idx) => {
        return <div key={item.id}>{item.element}</div>;
      })}
    </div>
  );
};
