import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/loading/Loading';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Input, Button, Form, Row, Col, Checkbox } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;

export const UpdateFormAllSettings = ({ updateProps, loadingBrand, updateModalFormRef, updateFormError, updateFormFields, editBtnLoader, handleOnFinish, handleOnUpload, imageUpdateData, setToggleUpdate, handleOnRemoveUpload }) => {
  const [description, setDescription] = useState(updateFormFields.find((field) => field.name === 'description').value);

  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (loadingBrand) {
      handleInitial();
    }
  }, [updateFormFields]);

  const handleInitial = () => {
    setDescription(updateFormFields.find((field) => field.name === 'description').value);
  };

  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Uredi brend">
      {!loadingBrand ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
          <div className="form-group upload-image">
            {!!updateProps.data.image && updateProps.data.image.length !== 0 ? (
              <>
                <div className="avatar">
                  <div className="image-wrapper">
                    <img src={updateProps.data.image.url} alt="Update category" data-cy="parent-update-category-image" className="img" />
                  </div>

                  <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'updateRemove' })}>
                    <span className="icon"></span>
                    <span className="text">Obriši sliku</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {imageUpdateData && imageUpdateData.blob ? (
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={imageUpdateData.blob} alt="Update category blob" data-cy="update-parent-new-image" className="img" />
                    </div>

                    <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'update' })}>
                      <span className="icon"></span>
                      <span className="text">Obriši sliku</span>
                    </button>
                  </div>
                ) : (
                  <div className="upload">
                    <div className="upload-wrapper d-flex gap-x-2">
                      <div>
                        <span className="text" data-cy="update-parent-image-placeholder">
                          <div className="text-center">
                            <p className="mb-0">Nema izabrane</p>
                            <p className="mb-0">slike</p>
                          </div>
                        </span>
                        <input
                          type="file"
                          name="updateImage"
                          id="updateUploadFile"
                          className="input-upload"
                          onChange={(event) =>
                            handleOnUpload({
                              name: event.target.name,
                              img: event.target.files[0],
                            })
                          }
                        />
                      </div>

                      <div className="validation-image">
                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageType ? 'error' : ''}`}>
                          * moguće je upload samo JPG/PNG sliku!
                        </p>

                        <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageSize ? 'error' : ''}`}>
                          * Slika mora biti manja od 2MB!
                        </p>
                      </div>
                    </div>
                    <label htmlFor="updateUploadFile" data-cy="update-parent-upload-btn" className="label btn btn-primary-outline btn-no-hover">
                      <span className="icon">
                        <img src="/assets/icons/upload.svg" alt="Update category" className="img" />
                      </span>
                      <span className="text">Objavi sliku</span>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>
          <Form.Item name="name" label="Naziv:" rules={[{ required: true, message: 'Unesite naziv brenda!' }]}>
            <Input
              placeholder="Naziv:"
              value={updateFormFields.name}
              onChange={(e) => {
                const value = e.target.value;
                const slugValue = value.toLowerCase().replace(/\s+/g, '-');
                value;
                updateModalFormRef.current.setFieldsValue({ slug: slugValue });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                const slugValue = value.toLowerCase().replace(/\s+/g, '-');
                value;
                updateModalFormRef.current.setFieldsValue({ slug: slugValue });
              }}
            />
          </Form.Item>
          <Form.Item name="slug" label="Slug:" rules={[{ required: true, message: 'Unesite slug za brend!' }]}>
            <Input placeholder="Slug:" value={updateFormFields.slugValue} onChange={(e) => e.target.value} />
          </Form.Item>

          <Form.Item name="description" label="Opis:" rules={[{ required: true, message: 'Unesite opis brenda!' }]}>
            <div className="select select-result">
              <Editor
                apiKey={apiKey}
                name="description"
                onEditorChange={(newValue, editor) => {
                  getTextValue(editor.getContent());
                  updateModalFormRef.current.setFieldsValue({ description: editor.getContent() });
                }}
                init={{
                  height: 600,
                  menubar: true,
                  entity_encoding: 'raw',
                  plugins: 'table link',
                }}
                value={description}
              />
            </div>
          </Form.Item>

          <Form.Item name="googleDescription" label="Meta opis:">
            <Input placeholder="Meta opis:" value={updateFormFields.googleDescription} onChange={(e) => e.target.value} />
          </Form.Item>

          <Form.Item name="keywords" label="Keywords:">
            <Input placeholder="Keywords:" value={updateFormFields.keywords} onChange={(e) => e.target.value} />
          </Form.Item>

          <Form.Item name="updateBrandId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Otkazati</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Sačuvaj izmene</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Uredjivanje...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
