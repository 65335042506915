import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../../../context/useAuth';
import { SERVER_URL } from '../../../../../config/index';
import Loading from '../../../../../components/loading/Loading';
import LoadingPlaceholder from '../../../../../components/loadingPlaceholder/LoadingPlaceholder';
import GalleryUpload from '../../GalleryUpload';
import { Section, Main } from '../../../../../components/content';
import { PERMISSIONS } from '../../../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../../../utils/endpoint';
import { ReferencesProvider, UseReferences } from '../../../../../context/references';
import { DeleteOutlined } from '@ant-design/icons';

const ReferenceGallery = () => {
  const { user } = useAuth();

  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [reRender, setReRender] = useState(false);
  const { imagesArray, setImagesArray } = UseReferences();

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [refetch, setRefetch] = useState(false);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const [toggle, setToggle] = useState(false);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const url = search === null ? `${AXIOS_API_CALL.slideshowgalleryImages}` : `${AXIOS_API_CALL.galleryImagesSearch}` + search;
      const response = await axios
        .get(`${SERVER_URL}/${url}`, {
          withCredentials: false,
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);

          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, search]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  // console.log(imagesArray);
  useEffect(() => {
    // console.log(imagesArray);
    setFileList(imagesArray);
  }, [readData]);

  const handleDeleteSelectedImage = (item, index) => {
    let arr = imagesArray;

    arr.splice(index, 1); // 2nd parameter means remove one item only

    console.log(arr, index);

    setImagesArray(arr);
    setReRender(!reRender);
  };

  return (
    <ReferencesProvider>
      {/* <Section>
        <Main className="section__content relative min-h-table-content">
          <GalleryUpload setImagesArray={setImagesArray} imagesArray={imagesArray} data={readData} setToggle={setToggle} setRefetch={setReadDataRefetch} fileList={fileList} setFileList={setFileList} />
        </Main>
      </Section> */}
      <Section className="section__wrapper section__products section--references">
        <div className="references-images-container">
          <div className="references-images-grid">
            {imagesArray && imagesArray.length > 0 ? (
              imagesArray.map((item, index) => {
                if (item && item.response) {
                  return (
                    <div
                      onClick={(e) => {
                        handleDeleteSelectedImage(e, index);
                      }}
                      className="refimage-container"
                    >
                      <img className="refimage" src={item.response.image.url} alt={item.response.image.originalname} />
                      <p className="refimage-deleteicon">
                        <DeleteOutlined />
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={(e) => {
                        handleDeleteSelectedImage(e, index);
                      }}
                      className="refimage-container"
                    >
                      <img className="refimage" src={item.url} alt={item.originalname} />
                      <p className="refimage-deleteicon">
                        <DeleteOutlined />
                      </p>
                    </div>
                  );
                }
              })
            ) : (
              <div className="refimage-container">
                <img className="refimage" src="/assets/images/card-placeholder.svg" />
                {/* <p className="refimage-deleteicon">
                <DeleteOutlined />
              </p> */}
              </div>
            )}
          </div>
        </div>
        <Section className="reference-images-section">
          <Main className="section__content relative min-h-table-content">
            <GalleryUpload setImagesArray={setImagesArray} imagesArray={imagesArray} data={readData} setToggle={setToggle} setRefetch={setReadDataRefetch} fileList={fileList} setFileList={setFileList} />
          </Main>
        </Section>
      </Section>
    </ReferencesProvider>
  );
};

export default ReferenceGallery;
