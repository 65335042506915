import { Wrapper } from '../../../../../components/content';
import { useBlogs } from '../../../../../context/blogs';
import { Description } from './description/Description';
import { Select } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../../../config/index';
import { useAuth } from '../../../../../context/useAuth';

export const BlogInfo = (props) => {
  const { user } = useAuth();
  const { form, onChange, formError } = useBlogs();
  const { className } = props;

  const { token } = user;

  return (
    <Wrapper className={`product__card ${className}`}>
      <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>Informacije o blogu</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="blogTitle">
                <b>Naslov bloga:</b>
              </label>
              <input value={form.blogTitle || ''} onChange={onChange} name="blogTitle" placeholder="Naziv bloga" type="text" id="blogTitle" className={`input ${formError.blogTitle ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.blogTitle ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="blogUrl">
                <b>Url:</b>
              </label>

              <input value={form.blogUrl || ''} onChange={onChange} name="blogSlug" placeholder="Url" type="text" id="blogUrl" className={`input ${formError.blogUrl ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.blogUrl ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="keywords">
                <b>Keywords:</b>
              </label>

              <input value={form.keywords || ''} onChange={onChange} name="keywords" placeholder="Keywords" type="text" id="keywords" className={`input ${formError.keywords ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.keywords ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="googleDescription">
                <b>Google Description:</b>
              </label>

              <input value={form.googleDescription || ''} onChange={onChange} name="googleDescription" placeholder="Google description" type="text" id="googleDescription" className={`input ${formError.googleDescription ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.googleDescription ? 'error' : ''}`}>
                * Ovo polje je obavezno!
              </p>
            </div>

            <div className="form-group">
              <Description label="Tekst bloga" className="product-description" />
            </div>
          </form>
        </main>
      </div>
    </Wrapper>
  );
};
