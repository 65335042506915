import React, { useEffect, useState } from 'react';
import { Wrapper, Col, Row } from '../../../components/content';
import ActionTypeButton from '../../../components/actionTypeButton/ActionTypeButton';
import InputTypeSelect from '../../../components/inputTypeSelect/InputTypeSelect';
import Loading from '../../../components/loading/Loading';

const GalleryContent = (props) => {
  const { select, selected, checked, setChecked, readData, setSearch, readDataLoading, setReadDataRefetch } = props;

  // SEARCH
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [selectedImage, setSelectedImage] = useState(null);
  // const [refetch, setRefetch] = useState(false);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // READ
  useEffect(() => {
    const selectedImage =
      readData &&
      readData?.reduce((acc, item) => {
        if (item._id === select) {
          acc = item;
        }
        return acc;
      }, {});
    setSelectedImage(selectedImage);
  }, [readData, select]);

  return (
    <>
      <Wrapper className="gallery-images-header">
        <div className="search">
          <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
            <div className="form-group m-0">
              <span className="icon icon-search">
                <img src="/assets/icons/search.svg" alt="Search" title="Search" />
              </span>
              <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Pretraži" onChange={(e) => handleOnChangeSearch(e.target.value)} />
              <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
              </span>
            </div>
            <button className="form-submit" data-cy="search-submit-btn">
              Pretraži
            </button>
          </form>
        </div>
      </Wrapper>
      {readDataLoading ? (
        <Loading />
      ) : (
        <>
          <Wrapper className="gallery-images-select">
            <Row>
              {readData &&
                readData?.map((item) => {
                  return (
                    <Col key={item._id}>
                      <div className="card">
                        <img className="img" src={item.url} alt={item.originalname} />
                      </div>
                      <InputTypeSelect item={item} idx={item._id} checked={checked} setChecked={setChecked} />
                    </Col>
                  );
                })}
            </Row>
          </Wrapper>

          <Wrapper className="gallery-images-actions">
            <div className="actions">
              <ActionTypeButton select={selectedImage} selected={selected} />
            </div>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default GalleryContent;
