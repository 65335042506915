const notificationMessages = {
  // AUTH
  successLogin: 'Uspešno ste prijavljeni!',
  failedLogin: 'Email ili lozinka su netačni!',
  successLogout: 'Uspešno ste se odjavili!',
  userAlreadyExists: 'Korisnik već postoji!',
  userMailVerified: 'Email verifikovan!',

  // ROLES - (create, update, delete)
  successCreateRole: 'Role su uspešno kreirane!',
  successUpdateRole: 'Roles su uspešno izmenjene!',
  successDeleteRole: 'Role su uspešno uklonjene!',
  successDeleteMultiRole: 'Role su uspešno uklonjene!',
  successUpdateStatusRole: 'Status role je uspešno ažuriran!',
  successUpdateStatusRoles: 'Status rola je uspešno ažuriran!',

  failedCreateRole: 'Ovaj naziv role već postoji!',
  failedUpdateRole: 'Ažuriranje role nije uspelo!',
  failedDeleteRole: 'Brisanje role nije uspelo!',
  failedUpdateStatusRole: 'Ažuriranje statusa role nije uspelo!',
  failedUpdateStatusRoles: 'Ažuriranje statusa rola nije uspelo!',

  // EMPLOYEES - (create, update, delete)
  successCreateEmployee: 'Korisnik je uspešno kreiran!',
  successUpdateEmployee: 'Korisnik je uspešno izmenjen!',
  successUpdateEmployeeEmail: 'Proverite svoj email za verifikaciju!',
  successDeleteEmployee: 'Korisnik je uspešno uklonjen!',
  successUpdateStatusEmployee: 'Status korisnika je uspešno promenjen!',
  successUpdateStatusMultiEmployee: 'Status korisnika je uspešno promenjen!',

  failedCreateEmployee: 'Kreiranje zaposlenog nije uspelo!',
  failedUpdateEmployee: 'Ažuriranje zaposlenog nije uspelo!',
  failedDeleteEmployee: 'Brisanje zaposlenog nije uspelo!',

  // CATEGORIES
  successCreateCategory: 'Kategorija je uspešno kreirana.',
  successCreateSubcategory: 'Podkategorija je uspešno kreirana.',

  // ATTRIBUTES
  successCreateAttribute: 'Atribut je uspešno kreiran.',

  // PROFILE - (update)
  successUpdateProfile: 'Informacije o profilu su uspešno ažurirane!',

  // BRANDS
  successCreateBrand: 'Brand je uspešno kreiran!',
  successDeleteBrand: 'Brand je uspešno uklonjen!',
  brandAlreadyExists: 'Brend već postoji!',
  failedDeleteBrand: 'Brisanje brenda nije uspelo!',
  successUpdateBrands: 'Brand je uspešno izmenjen!',
};

export { notificationMessages };
