import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteCategory = ({ deleteProps, deleteFormRef, handleOnSubmit, deleteBtnLoader, setToggleDelete }) => {
  return (
    <Modal {...deleteProps} className="sidebar__modal--center" title="">
      <form name="delete" ref={deleteFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: deleteFormRef })}>
        <h2 className="text-center mb-4">Da li ste sigurni da želite da izbrišete kategoriju ?</h2>

        <div className="form-group form-group-modal mb-4">
          {!deleteBtnLoader ? (
            <button data-cy="modal_delete_submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Da</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-link">
              <span className="text">Brisanje...</span>
            </button>
          )}

          <button data-cy="modal_delete_cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
            <span className="text">Otkazati</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
