import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { UseReferences } from '../../../../../../context/references';

const apiKey = process.env.REACT_APP_TINYMCE_APIKEY;
export const Description = (props) => {
  const { form, setForm, productEdit, formError } = UseReferences();
  const { label, className } = props;

  const [description, setDescription] = useState('');
  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (description && description.length > 0) {
      setForm((prevState) => {
        return { ...prevState, description: description };
      });
    }
  }, [description, setForm]);

  useEffect(() => {
    if (form.description !== '') {
      setDescription(form.description);
    }
  }, [form]);

  return (
    <div className="custom-select">
      <span className="label">
        <b>{label}:</b>
      </span>

      <div className={`select select-result input ${formError.description ? 'error' : ''}`}>
        <Editor
          apiKey={apiKey}
          onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
          init={{
            height: 600,
            menubar: true,
            plugins: 'table link',
            readonly: false,
            entity_encoding: 'raw',
          }}
          value={description}
        />
        <p data-cy="" className={`error__onblur ${formError.description ? 'error' : ''}`}>
          * Ovo polje je obavezno!
        </p>
      </div>
    </div>
  );
};
