import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/loading/Loading';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Input, Button, Form, Row, Col, Checkbox } from 'antd';

export const UpdateFormAllSettings = ({ updateProps, loadingBrand, updateModalFormRef, updateFormError, updateFormFields, editBtnLoader, handleOnFinish, handleOnUpload, imageUpdateData, setToggleUpdate, handleOnRemoveUpload }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Uredi deklaraciju">
      {!loadingBrand ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={updateProps.data} onFinish={(data) => handleOnFinish({ name: 'update', data: data, id: updateProps.data._id })} fields={updateFormFields}>
          <Form.Item name="name" label="Naziv:" rules={[{ required: true, message: 'Unesite naziv deklaracije!' }]}>
            <Input
              placeholder="Naziv:"
              value={updateFormFields.name}
              onChange={(e) => {
                const value = e.target.value;
                const slugValue = value.toLowerCase().replace(/\s+/g, '-');
                value;
                updateModalFormRef.current.setFieldsValue({ slug: slugValue });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                const slugValue = value.toLowerCase().replace(/\s+/g, '-');
                value;
                updateModalFormRef.current.setFieldsValue({ slug: slugValue });
              }}
            />
          </Form.Item>

          <Form.Item name="manufacturer" label="Proizvođač:">
            <Input placeholder="Proizvođač:" />
          </Form.Item>

          <Form.Item name="qualityClass" label="Klasa kvaliteta:">
            <Input placeholder="Klasa kvaliteta:" />
          </Form.Item>

          <Form.Item name="material" label="Materijal:">
            <Input placeholder="Materijal:" />
          </Form.Item>

          <Form.Item name="countryOfOrigin" label="Zemlja porekla:">
            <Input placeholder="Zemlja porekla:" />
          </Form.Item>

          <Form.Item name="importer" label="Uvoznik:">
            <Input placeholder="Uvoznik:" />
          </Form.Item>

          <Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Otkazati</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Sačuvaj izmene</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Uredjivanje...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
