import moment from 'moment';
import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';

export const ProductCreationDate = (props) => {
  const { form, setForm } = useProducts();

  return (
    <Wrapper className={`product__card product__card--tags`}>
      <header className="card-header">
        <h4>Kreirano: {moment(form.createdAt).format('DD. MM. YYYY.')}</h4>
      </header>
    </Wrapper>
  );
};
