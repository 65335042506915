import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { Link } from 'react-router-dom';

const Table = (props) => {
  // TABLE
  const { data = [], handleCheckedRows, handleDisabledButton, handleToggle } = props;

  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Naziv</th>
            <th>Proizvođač</th>
            <th>Klasa kvaliteta</th>
            <th>Materijal</th>
            <th>Zemlja porekla</th>
            <th>Uvoznik</th>
            <th>Akcija</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data &&
            data.data &&
            data.data.Data.map((item, index) => {
              const truncatedName = item.name.length > 30 ? `${item.name.substring(0, 30)}...` : item.name;
              const truncatedMaterial = item.material.length > 30 ? `${item.material.substring(0, 30)}...` : item.material;
              return (
                <tr>
                  <td>{truncatedName}</td>
                  <td>{item.manufacturer}</td>
                  <td>{item.qualityClass}</td>
                  <td>{truncatedMaterial}</td>
                  <td>{item.countryOfOrigin}</td>
                  <td>{item.importer}</td>
                  <td>
                    {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateProducts, PERMISSIONS.deleteProducts, PERMISSIONS.readProducts].includes(key.name)) && (
                      <Popover
                        className="tbody-actions"
                        placement="left"
                        trigger="click"
                        content={() => (
                          <div className="actions" data-cy="actions-popover-products">
                            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && (
                              <Link onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })} className="link btn-actions link-details">
                                <span className="icon d-flex">
                                  <img alt="Details" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
                                </span>
                                <span className="text">Izmeni</span>
                              </Link>
                            )}

                            {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteProducts) && (
                              <button
                                className="btn btn-actions"
                                type="button"
                                data-cy="actions-delete-product"
                                onClick={() =>
                                  handleToggle({
                                    name: 'delete',
                                    state: deleteActions,
                                    data: item._id,
                                  })
                                }
                              >
                                <span className="icon">
                                  <img alt="Delete" src="/assets/icons/delete.svg" />
                                </span>
                                <span className="text">
                                  <strong>IZBRIŠI</strong>
                                </span>
                              </button>
                            )}
                          </div>
                        )}
                      >
                        <button className="btn" type="button" data-cy="actions-toggle-products">
                          <span className="icon">
                            <img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
                          </span>
                        </button>
                      </Popover>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
