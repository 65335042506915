import { Pagination } from 'antd';
export const TabsContent = (props) => {
  const { tabsGallery, tabsContent, currentPage, handlePageChange } = props;

  return (
    <div className="tabs__panel-content">
      {tabsContent.map((item, idx) => {
        return (
          <div style={{ display: tabsGallery === idx ? 'block' : 'none' }} key={item.id}>
            {item.element}
            {item && item.name === 'Galerija' && <Pagination defaultCurrent={1} showSizeChanger={false} current={currentPage} onChange={handlePageChange} total={400} />}
          </div>
        );
      })}
    </div>
  );
};
